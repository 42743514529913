import React from 'react'
import {Outlet} from "react-router-dom";
import AdminNavigation from "../admin/admin-components/AdminNavigation";


const Admin = () => {
  return (
    <div className="admin-body">
      <AdminNavigation/>
      <Outlet/>
    </div>
  )
}

export default Admin