export const switchLang = (category, name) => {
  switch (category) {
    case "Flowers":
      return name[0];
    case "Accessories":
      return name[1];
    case "Descriptions":
      return name[2];

    default:
      return "";
  }
};
