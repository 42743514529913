import React from 'react';
import {CircularProgress} from "@mui/material";

const Circular = () => {
  return (
    <div className='circle'>
      <CircularProgress/>
    </div>
  );
};

export default Circular;