import React from 'react';

const Arrow = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 13.6797L0.320313 7L7 0.320312L8.17188 1.49219L3.52344 6.17969L13.6797 6.17969L13.6797 7.82031L3.52344 7.82031L8.17188 12.5078L7 13.6797Z"
      fill="black"/>
  </svg>

);


export default Arrow;