import { styled } from "@mui/material";

export const ProductBox = styled("div")({
  // maxWidth: "900px",
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "15px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});
export const ProductTabs = styled("div")({
  "& .MuiBox-root": {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
});
