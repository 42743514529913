import axiosInstance from "../../axios/axiosInstance";
import { setAddedProduct } from "../../features/products/productSlice";

export const addToOrder = async (
  item,
  userId,
  dispatch,
  buyingProduct,
  count,
) => {
  const newDate = new Date();

  try {
    const existingProductIndex = buyingProduct.findIndex(
      (product) => product.orderProducts[0].product.uuid === item.uuid,
    );

    if (existingProductIndex !== -1) {
      const orderId = buyingProduct[existingProductIndex].id;
      await axiosInstance.delete(`/users/${userId}/order/${orderId}`);
      dispatch(setAddedProduct(item));
    } else {
      const orderedProduct = await axiosInstance.post(
        `/users/${userId}/order`,
        {
          name: item.name,
          deliveryDate: newDate,
          orderItems: [
            {
              count: count || 1,
              productId: item.uuid,
            },
          ],
        },
      );

      dispatch(
        setAddedProduct({
          ...item,
          buyingPrice: item.price,
          count: 1,
          orderId: orderedProduct.data.data.id,
          isAdded: true,
        }),
      );
    }
  } catch (err) {
    console.log(err);
  }
};
