import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavigateSection from "../NavigateSection";
import Slider from "react-slick";
import {
  selectProductForBuying,
  setProductItem,
} from "../../features/products/productSlice";
import { selectFavorites } from "../../features/favorites/favoritesSlice";
import {
  productItemSlider,
  productItemSmallSlider,
} from "../../utils/sliderSettings";
import { useTranslation } from "react-i18next";
import AuthModal from "../auth/AuthModal";
import {
  selectUserAuth,
  selectUserId,
} from "../../features/authUser/authSlice";
import { useParams } from "react-router-dom";
import { selectLanguage } from "../../features/allProducts/allProducts";
import Circular from "../Circular";
import axiosInstance from "../../axios/axiosInstance";
import { switchLang } from "../../utils/switchcategoriesLang";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { axiosToggleFavorite } from "../../utils/Axios/axiosToggleFavorites";
import { addToOrder } from "../../utils/Axios/addToOrder";

const ProductsItem = () => {
  const lang = useSelector(selectLanguage);
  const { productID } = useParams();
  const [item, setItem] = useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const auth = useSelector(selectUserAuth);
  const buyingProduct = useSelector(selectProductForBuying);
  const favorite = useSelector(selectFavorites);

  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(0);
  const { name } = t("main.ourProduct");
  const { currency } = t("payment");
  const [categoriesLang, setCategoriesLang] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    if (slider1.current && slider2.current) {
      slider1.current.slickGoTo(0);
    }
  }, []);

  const getItems = async () => {
    try {
      // setIsLoading(true);
      const res = await axiosInstance.get(`/products/${productID}`, {
        headers: {
          "Accept-Language": lang,
        },
      });

      setItem(res.data?.data);
      setPrice(res.data?.data.discountPrice);
      setIsLoading(false);
      dispatch(setProductItem(res.data?.data));
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getItems();
  }, [lang, favorite]);

  const handleSlideClick = (index) => {
    if (slider1.current) {
      slider1.current.slickGoTo(index);
    }
  };

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    setPrice(count * item.discountPrice);
  }, [count]);

  const addProducts = async () => {
    if (!auth) {
      setOpen(true);
      return;
    }
    try {
      await addToOrder(item, userId, dispatch, buyingProduct, count);
    } catch (e) {
      console.log(e);
    }
  };
  const fetchFavorites = async () => {
    try {
      // setIsLoading(true);
      const userDataResponse = await axiosInstance.get(`/users/${userId}`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      setFavorites(userDataResponse?.data.data.products);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchFavorites();
    }
  }, [favorite]);
  const handleToggleFavorites = async () => {
    if (!auth) {
      setOpen(true);
    } else {
      try {
        await axiosToggleFavorite(item, userId, favorite, dispatch);
      } catch (error) {
        console.error("Error toggling favorites:", error);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setCategoriesLang(switchLang(item.category, name));
  }, [item, name]);

  return (
    <section className="section_padding">
      <NavigateSection
        link={categoriesLang}
        categories={t("categories")}
        subLink={item.category}
        subName={item.name}
      />
      {isLoading ? (
        <Circular />
      ) : (
        <div className="single-page">
          <div className="single-page_slider">
            <div className="slider_image">
              <Slider
                {...productItemSlider}
                asNavFor={slider2.current}
                ref={(slider) => (slider1.current = slider)}
              >
                {item?.imageURLs?.map((item, index) => (
                  <div key={index} className="single_slide-title">
                    <img
                      src={item}
                      className="single_slide-img"
                      alt={`slide-${index}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>

            <div className="small_slider">
              <Slider {...productItemSmallSlider} asNavFor={slider1.current}>
                {item.imageURLs?.map((item, index) => (
                  <div
                    key={`dot-${index}`}
                    className="slider_image-item"
                    onClick={() => handleSlideClick(index)}
                  >
                    <img src={item} alt={`dot-${index}`} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div className="single-page_title">
            <div className="single-page_text">
              <div className="single-page_name">
                <h3>{item.name}</h3>
              </div>
              <div className="single-page_description">
                <p>{item.description}</p>
              </div>
            </div>
            <div className="sigle-page_items-count">
              <div className="single-page_price">
                <div className="single-page_count">
                  <span className="page_discount">
                    {item.price > 1 && `${item.price} ${currency}`}
                  </span>
                  <span className="page_count">{`${price} ${currency}`}</span>
                </div>
              </div>
              <div className="md_item-title-quantity">
                <div className="btn-group">
                  <button onClick={decrement}>-</button>
                  <span className="item_quantityCount">{count}</span>
                  <button onClick={increment}>+</button>
                </div>
              </div>
            </div>

            <div className="data_buttons">
              <button
                className={
                  buyingProduct.some(
                    (product) =>
                      product.orderProducts[0].product.uuid === item.uuid,
                  )
                    ? "data_added-button"
                    : "data_add-button"
                }
                onClick={addProducts}
              >
                {buyingProduct.some(
                  (product) =>
                    product.orderProducts[0].product.uuid === item.uuid,
                )
                  ? "Added"
                  : "Add"}
              </button>
              <span className="data_heartIcon" onClick={handleToggleFavorites}>
                {favorites.some((e) => e.uuid === item.uuid) ? (
                  <FavoriteIcon sx={{ color: "#F56E91", fontSize: "30px" }} />
                ) : (
                  <FavoriteBorderIcon
                    sx={{ color: "#555", fontSize: "30px" }}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
      )}

      {open && <AuthModal open={open} onClose={handleClose} />}
    </section>
  );
};

export default ProductsItem;
