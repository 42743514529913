import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { AdminHeader, Section } from "../../../assets/adminStyles/user/users";
import { db, storage } from "../../../firebase/firebase";
import Circular from "../../Circular";

const AdminSlides = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [text, setText] = useState("");
  const [deliveryTitle, setDeliveryTitle] = useState("");
  const [deliverySubtitle, setDeliverySubtitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setImageUrl(file);
      };
      reader.readAsDataURL(file);
    } else {
      setImageUrl("");
      setImage(null);
    }
  };
  const handleRemove = () => {
    setImage(null);
    setText("");
    setDeliveryTitle("");
    setDeliverySubtitle("");
  };

  const handleAdd = async () => {
    const name = new Date().getTime().toString();
    const storageRef = ref(storage, `HomeSlider/${name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageUrl);

    try {
      await uploadTask.on(
        "state_changed",
        (snapshot) => {
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log("Upload image error - ", error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            await addDoc(collection(db, "HomeSlider"), {
              src: downloadURL,
              alt: name,
              categories: "HomeSlider",
              text,
              deliveryTitle,
              deliverySubtitle,
            });
          } catch (error) {
            console.error("Error adding document to Firestore:", error);
          }
        },
      );

      setImageUrl("");
      setImage("");
      setText("");
      setDeliveryTitle("");
      setDeliverySubtitle("");
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    setIsLoading(false);
    const getItems = onSnapshot(
      collection(db, "HomeSlider"),
      (snapshot) => {
        let items = [];
        snapshot.docs.forEach((doc) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        setImageUrls(items);
        setIsLoading(true);
      },
      (error) => {
        setIsLoading(false);
      },
    );

    return () => {
      getItems();
    };
  }, []);

  const handleDeleteItem = async (image) => {
    try {
      const desertRef = ref(storage, `HomeSlider/${image.alt}`);
      await deleteObject(desertRef);
      await deleteDoc(doc(db, "HomeSlider", image.id));
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Section>
      <AdminHeader>Slides</AdminHeader>

      <div className="slides-box">
        <div className="add-image">
          <div>
            <TextField
              id="outlined-basic"
              label="Text"
              variant="outlined"
              placeholder="EN"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Delivery title"
              variant="outlined"
              placeholder="EN"
              value={deliveryTitle}
              onChange={(e) => setDeliveryTitle(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Delivery subtitle"
              variant="outlined"
              placeholder="EN"
              value={deliverySubtitle}
              onChange={(e) => setDeliverySubtitle(e.target.value)}
            />
          </div>
          <div className="image-frame">
            {image && <img src={image} alt="Preview" className="preview-img" />}
            <label className="input-img">
              {image ? (
                ""
              ) : (
                <>
                  <AddAPhotoIcon
                    sx={{
                      color: "#3963AD",
                      cursor: "pointer",
                      fontSize: "35px",
                    }}
                  />
                  <p>Add Photo</p>
                  <input
                    accept=".jpg, .jpeg, .png"
                    type="file"
                    onChange={handleImageChange}
                  />
                </>
              )}
            </label>
          </div>

          <div className="btn-group">
            <Button
              variant="contained"
              size="large"
              sx={{
                marginTop: "10px",
                width: "45%",
                backgroundColor: "error",
              }}
              onClick={() => handleAdd()}
            >
              Add
            </Button>
            <Button
              variant="contained"
              size="large"
              color="error"
              sx={{
                marginTop: "10px",
                width: "45%",
                backgroundColor: "error",
              }}
              onClick={() => handleRemove()}
            >
              Remove
            </Button>
          </div>
        </div>

        {isLoading ? (
          <div className="all-images">
            {imageUrls.length ? (
              imageUrls?.map((item) => (
                <div className="slide-image" key={item.alt}>
                  <img src={item.src} alt={item.alt} />
                  <button onClick={() => handleDeleteItem(item)}>
                    <CancelRoundedIcon
                      sx={{
                        color: "red",
                        fontSize: "35px",
                      }}
                    />
                  </button>
                </div>
              ))
            ) : (
              <h4>No Items</h4>
            )}
          </div>
        ) : (
          <Circular />
        )}
      </div>
    </Section>
  );
};

export default AdminSlides;
