import React from 'react';

const EmptyCartIcon = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="300" height="280" viewBox="0 0 1344 1024">
    <title></title>
    <g id="icomoon-ignore">
    </g>
    <path fill="#958D8D"
          d="M1264 448h-1184c-4.928 0-9.6 2.272-12.64 6.144s-4.096 8.928-2.912 13.728l128.192 512.544c3.072 10.784 12.512 43.584 47.36 43.584h864c34.976 0 44.32-32.832 47.52-44.128l128-512c1.184-4.768 0.128-9.856-2.912-13.728-3.008-3.872-7.68-6.144-12.608-6.144zM1120.64 971.616c-5.824 20.384-11.84 20.384-16.64 20.384h-864c-4.768 0-10.752 0-16.48-19.872l-123.008-492.128h1143.008l-122.88 491.616zM592 896c8.832 0 16-7.168 16-16v-288c0-8.832-7.168-16-16-16s-16 7.168-16 16v288c0 8.832 7.168 16 16 16zM367.968 896c0.608 0 1.184-0.032 1.824-0.096 8.768-0.992 15.104-8.896 14.112-17.664l-32-288c-0.96-8.8-8.64-15.008-17.696-14.144-8.768 0.992-15.104 8.896-14.112 17.664l32 288c0.896 8.192 7.84 14.24 15.872 14.24zM784 896c8.832 0 16-7.168 16-16v-288c0-8.832-7.168-16-16-16s-16 7.168-16 16v288c0 8.832 7.168 16 16 16zM974.208 895.904c0.64 0.064 1.216 0.096 1.824 0.096 8.032 0 14.976-6.048 15.872-14.24l32-288c0.96-8.768-5.344-16.672-14.112-17.664-9.184-0.896-16.704 5.344-17.696 14.144l-32 288c-0.96 8.768 5.344 16.672 14.112 17.664zM1104 384c3.712 0 7.424-1.28 10.464-3.904 6.688-5.792 7.392-15.872 1.632-22.56l-304-352c-5.792-6.72-15.872-7.392-22.56-1.664-6.688 5.792-7.392 15.872-1.632 22.56l304 352c3.168 3.68 7.616 5.568 12.096 5.568zM570.112 3.616c-6.816-5.536-16.896-4.608-22.496 2.272l-288 352c-5.6 6.816-4.608 16.896 2.24 22.528 2.976 2.4 6.592 3.584 10.144 3.584 4.64 0 9.216-1.984 12.384-5.888l288-352c5.6-6.816 4.576-16.896-2.272-22.496zM1328 320h-128c-8.832 0-16 7.168-16 16s7.168 16 16 16h128c8.832 0 16-7.168 16-16s-7.168-16-16-16zM432 352h512c8.832 0 16-7.168 16-16s-7.168-16-16-16h-512c-8.832 0-16 7.168-16 16s7.168 16 16 16zM16 352h160c8.832 0 16-7.168 16-16s-7.168-16-16-16h-160c-8.832 0-16 7.168-16 16s7.168 16 16 16z"></path>
  </svg>

)

export default EmptyCartIcon;