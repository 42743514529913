import React from "react";
import { useTranslation } from "react-i18next";
import Products from "../components/products/Products";

const Decorations = () => {
  const { t } = useTranslation();
  const { name } = t("main.ourProduct");
  return <Products categoryName={name[2]} category="Decorations" />;
};

export default Decorations;
