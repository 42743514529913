import React from "react";

const SendButton = () => (
  <svg
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.5" cy="16.1421" r="15.5" fill="#B0D404" />
    <path
      d="M13 21.6421L18 16.6421L13 11.6421"
      stroke="#0B0B0B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SendButton;
