import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import axiosPostInstance from "../../../../axios/axiosPostInstance";
import { useNavigate } from "react-router-dom";
import { getCheckedProduct } from "../../utils/getCheckedProduct";

const AdminAddProduct = ({ lang, label }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [categories, setCategories] = useState("Flowers");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [checkedNames, setCheckedNames] = useState([]);

  const [checkedProduct, setCheckedProduct] = useState(() =>
    getCheckedProduct("Flowers"),
  );

  const handleSelect = (event) => {
    const selectedCategory = event.target.value;
    setCategories(selectedCategory);
    setCheckedProduct(() => getCheckedProduct(selectedCategory));
  };

  const handleChecked = (event) => {
    const { name, checked } = event.target;
    setCheckedProduct({ ...checkedProduct, [name]: checked });

    if (checked) {
      setCheckedNames((prevCheckedNames) => [...prevCheckedNames, name]);
    } else {
      setCheckedNames((prevCheckedNames) =>
        prevCheckedNames.filter((checkedName) => checkedName !== name),
      );
    }
  };

  const handleInputImage = (e) => {
    const files = e.target.files;
    setImage(files);
    const uploadedImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = () => {
        uploadedImages.push(reader.result);
        if (uploadedImages.length === files.length) {
          setImageURLs((prevURLs) => [...prevURLs, ...uploadedImages]);
        }
      };

      reader.readAsDataURL(file);
    }
  };
  const handleRemoveImage = (imageURLToRemove, index) => {
    const removedImages = imageURLs.filter((url) => url !== imageURLToRemove);
    const updatedFiles = [...image];
    updatedFiles.splice(index, 1);
    setImageURLs(removedImages);
    setImage(updatedFiles);
  };

  const handleAdd = async () => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("discountPrice", discountPrice);
      formData.append("category", categories);
      checkedNames.forEach((name) => {
        formData.append("subCategories", name);
      });

      for (let i = 0; i < image.length; i++) {
        formData.append(`imageURLs`, image[i]);
      }

      await axiosPostInstance.post("/products", formData, {
        headers: {
          "Accept-Language": lang,
        },
      });
      setIsUploading(false);
      setName("");
      setDescription("");
      setPrice(0);
      setDiscountPrice(0);
      setImageURLs([]);
      setCategories("");
      setCheckedNames([]);
      setIsError(false);
      setCheckedProduct([]);
      navigate("/");
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorMessage(error.response.data.message);
      setIsUploading(false);
      setIsError(true);
    }
  };

  return isUploading ? (
    <CircularProgress disableShrink />
  ) : (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {isError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Categories</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={categories}
          required
          label="Categories"
          onChange={handleSelect}
        >
          <MenuItem value={"Flowers"}>Flowers</MenuItem>
          <MenuItem value={"Accessories"}>Accessories</MenuItem>
          <MenuItem value={"Decorations"}>Decorations</MenuItem>
        </Select>
      </FormControl>
      <FormGroup sx={{ flexDirection: "row" }}>
        {Object.entries(checkedProduct).map(([name, checked]) => (
          <FormControlLabel
            key={name}
            control={
              <Checkbox
                checked={checked}
                name={name}
                onChange={handleChecked}
              />
            }
            label={name.charAt(0).toUpperCase() + name.slice(1)}
          />
        ))}
      </FormGroup>
      <FormControl
        sx={{
          display: "flex",
          gap: "10px",
          width: "100%",
        }}
      >
        <TextField
          label={label.name}
          variant="outlined"
          required
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          required
          label={label.description}
          variant="outlined"
          multiline
          fullWidth
          inputProps={{
            style: {
              height: "250px",
              overflow: "auto",
            },
            maxLength: 600,
          }}
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </FormControl>
      <div className="image-for-preview">
        <div className="image-frame">
          <label className="input-img">
            <AddAPhotoIcon
              sx={{
                color: "#3963AD",
                cursor: "pointer",
                fontSize: "35px",
              }}
            />
            <p>Add Photo</p>
            <input
              multiple
              accept=".jpg, .jpeg, .png"
              type="file"
              onChange={handleInputImage}
            />
          </label>
        </div>
        <div className="edit-images">
          {imageURLs?.map((imageUrl, index) => (
            <div className="add-edit-images" key={index}>
              <img
                src={imageUrl}
                alt={index.toString()}
                className="preview-img"
              />
              <span onClick={() => handleRemoveImage(imageUrl, index)}>
                <CancelIcon />
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="add-price">
        <FormControl
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            label="Product Price"
            variant="outlined"
            required
            name="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <TextField
            label="Product discount"
            variant="outlined"
            required
            name="discountPrice"
            value={discountPrice}
            onChange={(e) => setDiscountPrice(e.target.value)}
          />
        </FormControl>
        {/*</div>*/}
      </div>
      <Button
        variant="contained"
        size="large"
        color="primary"
        sx={{
          width: "260px",
          display: "block",
          mt: 2,
        }}
        onClick={handleAdd}
      >
        Add Product
      </Button>
    </div>
  );
};

export default AdminAddProduct;
