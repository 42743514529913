import { getCheckedFlowers } from "./getCheckedFlowers";
import { getCheckedAccessories } from "./getCheckedAccessories";
import { getCheckedDecorations } from "./getCheckedDecorations";
import { getCheckedAll } from "./getCheckedAll";

export const checkedSubCategories = (subCategoryName) => {
  switch (subCategoryName) {
    case "":
      return getCheckedAll();
    case "Flowers":
      return getCheckedFlowers();
    case "Accessories":
      return getCheckedAccessories();
    case "Decorations":
      return getCheckedDecorations();

    default:
      return [];
  }
};
