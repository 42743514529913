import React, {useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {adminNavList} from "../../../utils/adminNavList";
import logo from "../../../assets/pic/logo.png";
import {useDispatch} from "react-redux";
import {removeUser} from "../../../features/authUser/authSlice";
import {signOut} from "firebase/auth";
import {auth} from "../../../firebase/firebase";
import LogoutIcon from '@mui/icons-material/Logout';

const AdminNavigation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const handleSignOut = async (e) => {
    e.preventDefault();

    try {
      await signOut(auth);
      dispatch(removeUser());
      navigate('/');
    } catch (err) {
      console.log(err.message);
    }
  }

  return (
    <div className={isOpen ? 'admin-navigation' : 'admin-navigation open'}>
      <div className="admin-logo">
        <img
          src={logo}
          alt="logo"
          className="logo"
          onClick={() => setIsOpen(!isOpen)}
        />
        <h5>Administrator</h5>
      </div>

      <main className='admin-nav-link'>
        {adminNavList.map((link) => (
          <NavLink
            key={link.id}
            to={link.to}
            className={({isActive}) => (isActive ? "active" : "link")}
          >
            {link.icon}
            {isOpen ? link.name : ''}
          </NavLink>
        ))}
      </main>

      <button
        className='logOut'

        onClick={handleSignOut}>
        <LogoutIcon/>
        {isOpen && 'Log Out'}
      </button>

    </div>
  )
}

export default AdminNavigation