import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Section } from "../../../assets/adminStyles/user/users";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axiosPostInstance from "../../../axios/axiosPostInstance";
import { useNavigate, useParams } from "react-router-dom";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CancelIcon from "@mui/icons-material/Cancel";
import Circular from "../../Circular";
import { getCheckedProduct } from "../utils/getCheckedProduct";

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState({});
  const [showImages, setShowImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [data, setData] = useState({
    category: "",
    description: "",
    discountPrice: 0,
    imageURLs: [],
    name: "",
    price: 0,
    subCategories: [],
  });

  const [checkedProduct, setCheckedProduct] = useState({});
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const editData = await axiosPostInstance.get(`/products/${id}`);
      const updateData = editData.data.data;
      setData({
        ...data,
        category: updateData.category,
        description: updateData.description,
        discountPrice: updateData.discountPrice,
        imageURLs: updateData.imageURLs,
        name: updateData.name,
        price: updateData.price,
        subCategories: updateData.subCategories,
      });
      setCheckedProduct(getCheckedProduct(updateData.category));
      setShowImages(updateData.imageURLs);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelect = (e) => {
    const category = e.target.value;
    setData({ ...data, category, subCategories: [] });
    setCheckedProduct(getCheckedProduct(category));
    setIsError(false);
  };

  const handleChecked = (event) => {
    const { name, checked } = event.target;
    setCheckedProduct({ ...checkedProduct, [name]: checked });

    let updatedSubCategories;
    if (checked) {
      updatedSubCategories = [...data.subCategories, name];
    } else {
      updatedSubCategories = data.subCategories.filter(
        (subCategory) => subCategory !== name,
      );
    }
    setData((prevData) => ({
      ...prevData,
      subCategories: updatedSubCategories,
    }));

    setIsError(false);
  };
  const handleInputImage = (e) => {
    const files = e.target.files;
    setImage(files);
    const uploadedImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = () => {
        uploadedImages.push(reader.result);
        if (uploadedImages.length === files.length) {
          setData({
            ...data,
            imageURLs: [...data.imageURLs, ...uploadedImages],
          });
          setShowImages((prevURLs) => [...prevURLs, ...uploadedImages]);
        }
      };

      reader.readAsDataURL(file);
    }
    setIsError(false);
  };
  const handleRemoveImage = (imageURLToRemove) => {
    const updatedImageURLs = data.imageURLs.filter(
      (url) => url !== imageURLToRemove,
    );

    setData({
      ...data,
      imageURLs: updatedImageURLs,
    });
    if (showImages.length) {
      setShowImages((prevURLs) =>
        prevURLs.filter((url) => url !== imageURLToRemove),
      );
    }

    setIsError(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setIsError(false);
  };

  const handleUpdateProduct = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("price", data.price);
      formData.append("discountPrice", data.discountPrice);
      formData.append("category", data.category);

      for (let i = 0; i < data.subCategories.length; i++) {
        formData.append("subCategories", data.subCategories[i]);
      }

      for (let i = 0; i < image.length; i++) {
        formData.append("imageURLs      ", image[i]);
      }

      const response = await axiosPostInstance.patch(
        `/products/${id}`,
        formData,
      );

      setSuccess(response.data.message);
      setIsError(false);
      setIsLoading(false);
      navigate("/");
    } catch (err) {
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(err.response.data.message);
    }
  };

  return (
    <Section>
      {isLoading ? (
        <Circular />
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {isError && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage.toUpperCase()}
            </Alert>
          )}
          {success && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {success}
            </Alert>
          )}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.category}
              required
              label="Categories"
              onChange={handleSelect}
            >
              <MenuItem value={"Flowers"}>Flowers</MenuItem>
              <MenuItem value={"Accessories"}>Accessories</MenuItem>
              <MenuItem value={"Decorations"}>Decorations</MenuItem>
            </Select>
          </FormControl>
          <FormGroup sx={{ flexDirection: "row" }}>
            {Object.entries(checkedProduct).map(([name, checked]) => (
              <FormControlLabel
                key={name}
                control={
                  <Checkbox
                    checked={checked || data.subCategories.includes(name)}
                    name={name}
                    onChange={handleChecked}
                  />
                }
                label={name.charAt(0).toUpperCase() + name.slice(1)}
              />
            ))}
          </FormGroup>

          <FormControl
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
            }}
          >
            <TextField
              variant="outlined"
              required
              name="name"
              value={data.name}
              onChange={handleInputChange}
            />
            <TextField
              required
              variant="outlined"
              multiline
              fullWidth
              inputProps={{
                style: {
                  height: "300px",
                  overflow: "auto",
                },
              }}
              name="description"
              value={data.description}
              onChange={handleInputChange}
            />
          </FormControl>
          <div className="image-for-preview">
            <div className="image-frame">
              <label className="input-img">
                <AddAPhotoIcon
                  sx={{
                    color: "#3963AD",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
                <p>Add Photo</p>
                <input
                  multiple
                  accept=".jpg, .jpeg, .png"
                  type="file"
                  onChange={handleInputImage}
                />
              </label>
            </div>
            <div className="edit-images">
              {showImages.map((url, index) => (
                <div className="add-edit-images" key={index}>
                  <img
                    src={url}
                    alt={index.toString()}
                    className="preview-img"
                  />
                  <span onClick={() => handleRemoveImage(url)}>
                    <CancelIcon />
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="add-price">
            <FormControl
              sx={{
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <TextField
                label="Product Price"
                variant="outlined"
                required
                name="price"
                value={data.price}
                onChange={handleInputChange}
              />
              <TextField
                label="Product discount"
                variant="outlined"
                required
                name="discountPrice"
                value={data.discountPrice}
                onChange={handleInputChange}
              />
            </FormControl>
            {/*</div>*/}
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{
                width: "260px",
                display: "block",
                mt: 2,
              }}
              onClick={handleUpdateProduct}
            >
              Update
            </Button>
          </div>
        </div>
      )}
    </Section>
  );
};

export default EditProduct;
