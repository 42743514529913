import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import {
  AdminHeader,
  Section,
  TableBox,
} from "../../../../assets/adminStyles/user/users";
import { db } from "../../../../firebase/firebase";

import Circular from "../../../Circular";
import axiosInstance from "../../../../axios/axiosInstance";
import DeleteUser from "./DeleteUser";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setUserToDelete(null);
  };
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
      const userList = [];
      snapshot.docs.forEach((doc) => {
        userList.push({ id: doc.id, ...doc.data() });
      });
      setUsers(userList);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleConfirm = () => {
    setConfirmed(true);
    setWarning(false);
    setOpen(false);
  };

  const handleDelete = async (e, item) => {
    e.preventDefault();
    setUserToDelete(item);
    setOpen(true);
  };
  useEffect(() => {
    const deleteUserAccount = async () => {
      if (!userToDelete) return;

      try {
        setIsLoading(true);
        if (process.env.REACT_APP_FIREBASE_ADMIN === userToDelete.uid) {
          setWarning(true);
          setIsLoading(false);
          setUserToDelete(null);
          return;
        }
        const userDocRef = doc(db, "users", userToDelete.id);
        await deleteDoc(userDocRef);
        await axiosInstance.delete(`/users/${userToDelete.uid}`);
        setIsLoading(false);
        setWarning(false);
        setUsers(users.filter((user) => user.id !== userToDelete.id));
      } catch (e) {
        setIsLoading(false);
        setOpen(false);
        setWarning(true);
      }
    };

    if (confirmed) {
      deleteUserAccount();
      setConfirmed(false);
    }
  }, [confirmed, userToDelete, users]);
  return (
    <Section component="section">
      <AdminHeader>User Information</AdminHeader>
      {warning && (
        <Alert severity="error">
          <AlertTitle>Warning</AlertTitle>
          You can't delete Admin user
        </Alert>
      )}
      <TableBox>
        {!isLoading ? (
          <TableContainer component={Paper} sx={{ padding: "10px" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Email</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell align="right">Image</TableCell>
                  <TableCell align="right">Delete User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((item) => (
                  <TableRow key={item.email}>
                    <TableCell sx={{ padding: "5px 10px" }} align="right">
                      {item.email}
                    </TableCell>
                    <TableCell
                      sx={{ padding: "5px 10px" }}
                      component="th"
                      scope="row"
                    >
                      {" "}
                      {item.displayName}
                    </TableCell>
                    <TableCell sx={{ padding: "5px 10px" }} align="right">
                      {item.photo ? (
                        <img
                          src={item?.photo}
                          alt={item.id}
                          className="user-table_image"
                        />
                      ) : (
                        <AccountBoxIcon fontSize={"large"} />
                      )}
                    </TableCell>
                    <TableCell sx={{ padding: "5px 10px" }} align="right">
                      <Button
                        variant="contained"
                        color="error"
                        onClick={(e) => handleDelete(e, item)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Circular />
        )}
      </TableBox>
      {open && (
        <DeleteUser
          open={open}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      )}
    </Section>
  );
};

export default AdminUsers;
