import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { cartSlider } from "../../utils/sliderSettings";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useNavigate } from "react-router-dom";
import {
  selectProductForBuying,
  setProductDescription,
} from "../../features/products/productSlice";
import { axiosToggleFavorite } from "../../utils/Axios/axiosToggleFavorites";
import { addToOrder } from "../../utils/Axios/addToOrder";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axios/axiosInstance";
import { selectFavorites } from "../../features/favorites/favoritesSlice";
import {
  selectUserAuth,
  selectUserId,
} from "../../features/authUser/authSlice";
import AuthModal from "../auth/AuthModal";

const Item = ({ item, lang, homePage, category }) => {
  const dispatch = useDispatch();
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();
  const favorite = useSelector(selectFavorites);
  const auth = useSelector(selectUserAuth);
  const userId = useSelector(selectUserId);
  const { t } = useTranslation();
  const { currency } = t("payment");
  const { add, added } = t("btnGroup");
  const buyingProduct = useSelector(selectProductForBuying);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const isProductAdded = buyingProduct.some(
    (product) => product.orderProducts[0].product.uuid === item.uuid,
  );

  const buttonClassName = isProductAdded
    ? "data_added-button"
    : "data_add-button";

  const buttonText = isProductAdded ? added : add;

  const handleClick = (e) => {
    addProducts(e, item);
  };
  const fetchFavorites = async () => {
    try {
      setIsLoading(true);
      const userDataResponse = await axiosInstance.get(`/users/${userId}`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      setFavorites(userDataResponse?.data.data.products);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchFavorites();
    }
  }, [favorite]);

  const handleToggleFavorites = async (item) => {
    if (!auth) {
      setOpen(true);
      return;
    }
    await axiosToggleFavorite(item, userId, favorites, dispatch);
  };

  const addProducts = async (e, item) => {
    e.preventDefault();

    if (!userId) {
      setOpen(true);
      return;
    }
    try {
      await addToOrder(item, userId, dispatch, buyingProduct);
    } catch (e) {
      console.log(e);
    }
  };

  const handleProductItemClick = (item) => {
    dispatch(
      setProductDescription({
        ...item,
        price: item.price,
      }),
    );
    navigate(`/categories/${category}/${item.uuid}`);
  };
  return (
    <div className="data-frame" key={item.uuid}>
      {homePage ? (
        <div>
          <img
            src={item.imageURLs[0]}
            alt={item.name}
            className="data_img"
            onClick={() => handleProductItemClick(item)}
          />
        </div>
      ) : (
        <Slider {...cartSlider}>
          {item.imageURLs?.map((url, index) => (
            <div key={index}>
              <img
                src={url}
                alt={item.name}
                className="data_img"
                onClick={() => handleProductItemClick(item)}
              />
            </div>
          ))}
        </Slider>
      )}
      <div className="cart-frame">
        <h4 className="data_title">
          {item.name.length < 19 ? item.name : item.name.slice(0, 16) + " ..."}
        </h4>
        <div className="data_price">
          <span className="data_discount">
            {item.price > 1 && `${item.price} ${currency}`}
          </span>
          <span className="data_count">
            {`${item.discountPrice} ${currency}`}
          </span>
        </div>
        <div className="data_buttons">
          <button className={buttonClassName} onClick={handleClick}>
            {buttonText}
          </button>
          <span
            className="data_heartIcon"
            onClick={() => handleToggleFavorites(item)}
          >
            {favorites.some((e) => e.uuid === item.uuid) ? (
              <FavoriteIcon sx={{ color: "#F56E91", fontSize: "30px" }} />
            ) : (
              <FavoriteBorderIcon sx={{ color: "#555", fontSize: "30px" }} />
            )}
          </span>
        </div>
      </div>
      {open && <AuthModal open={open} onClose={handleClose} />}
    </div>
  );
};

export default Item;
