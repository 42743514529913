import { styled } from "@mui/material";

export const Section = styled("section")({
  padding: " clamp(0.9375rem, 0.3125rem + 3.125vw, 3.125rem)",
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  backgroundColor: "#F0F1F3",
});
export const AdminHeader = styled("h2")({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "36px",
  fontWeight: "500",
});
export const TableBox = styled("div")({
  minHeight: "700px",
  maxWidth: "100%",
  width: "800px",
  backgroundColor: "#fff",
});
