import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    toggleFavorites: (state, action) => {
      let { uuid } = action.payload;
      const isDuplicate = state.some((item) => item.uuid === uuid);
      if (!isDuplicate) {
        state.push(action.payload);
      } else {
        return state.filter((item) => item.uuid !== uuid);
      }
    },
    removeFavorite: (state) => {
      return (state = []);
    },
  },
});

export const { toggleFavorites, removeFavorite } = favoritesSlice.actions;
export default favoritesSlice.reducer;

export const selectFavorites = (state) => state.favorite;
