import React from "react";
import { AdminHeader, Section } from "../../../assets/adminStyles/user/users";

const AdminDashboard = () => {
  return (
    <div>
      <Section>
        <AdminHeader>Dashboard</AdminHeader>
      </Section>
    </div>
  );
};

export default AdminDashboard;
