import React from "react";
import Slider from "react-slick";
import {ourClientSettings} from "../utils/sliderSettings";
import {useTranslation} from "react-i18next";


const OurClientOpinionBlock = () => {
  const {t} = useTranslation()
  const {title, options} = t('main.ourClientOptions')

  return (
    <div className="our-client-opinion">
      <h2 className="our-client-opinion-block-title">{title}</h2>
      <div className="our-client-opinion-block-bg">
        <Slider {...ourClientSettings}>
          {options.map((item, index) => (
            <div key={index} className="our-client_opinion-bg">
              <div className="our-client_slider-text">
                <p className="our-client_slider-description">{item.text}</p>
                <p className="our-client_user-name">{item.author}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}


export default OurClientOpinionBlock;
