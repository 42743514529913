import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductForBuying,
  selectAddedProduct,
} from "../features/products/productSlice";
import { selectUserAuth, selectUserId } from "../features/authUser/authSlice";
import AuthModal from "../components/auth/AuthModal";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { selectLanguage } from "../features/allProducts/allProducts";
import axiosInstance from "../axios/axiosInstance";
import { selectFavorites } from "../features/favorites/favoritesSlice";

const MainIcons = () => {
  const lang = useSelector(selectLanguage);
  const auth = useSelector(selectUserAuth);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();
  const counts = useSelector(selectAddedProduct);
  const [count, setCount] = useState(0);
  const [favorites, setFavorites] = useState([]);
  const favorite = useSelector(selectFavorites);
  const [open, setOpen] = useState(false);

  const fetchUserData = async () => {
    try {
      if (userId) {
        const dataCountResponse = await axiosInstance.get(
          `/users/${userId}/order`,
        );
        const dataCount = dataCountResponse.data.data;
        setCount(dataCount);
        dispatch(getProductForBuying(dataCount));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [lang, userId, counts, dispatch]);

  const fetchFavorites = async () => {
    try {
      if (userId) {
        const userData = await axiosInstance.get(`/users/${userId}`, {
          headers: {
            "Accept-Language": lang,
          },
        });
        setFavorites(userData?.data?.data?.products);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchFavorites();
  }, [favorite, userId, lang]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="main_icons">
      {userId ? (
        <>
          <Link to="/cart/basket" className="main_buying">
            <span className={count.length && "buying_icon"}>
              <LocalGroceryStoreOutlinedIcon
                sx={{ fontSize: 25, color: "#555" }}
              />
            </span>
            {count.length > 0 && (
              <span className="buying_count">{count.length}</span>
            )}
          </Link>

          {auth && (
            <Link to="/favourites" className="favorites">
              {favorites.length ? (
                <FavoriteIcon sx={{ color: "#F56E91" }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: "#555" }} />
              )}
              {favorites?.length > 0 && (
                <span className="favorite_count">{favorites.length}</span>
              )}
            </Link>
          )}
          <div className="main_user" onClick={handleClickOpen}>
            <PersonOutlineIcon sx={{ fontSize: "30px", color: "#555" }} />
          </div>
        </>
      ) : (
        <div className="main_user" onClick={handleClickOpen}>
          <PersonOutlineIcon sx={{ fontSize: "30px", color: "#555" }} />
        </div>
      )}

      {open && <AuthModal open={open} onClose={handleClose} />}
    </div>
  );
};

export default MainIcons;
