import React from "react";

const SlideNextBtn = () => (
<svg 
    className = "SlideNextBtn"
    width="13" 
    height="20" 
    viewBox="0 0 13 20" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
>
<path 
    d="M0.296875 17.6563L7.95312 10L0.296875 2.34375L2.64062 0L12.6406 10L2.64062 20L0.296875 17.6563Z" 
    fill="white"
/>
</svg>

);

export default SlideNextBtn;
