import React from "react";

const SlidePrevBtn = () => ( 
  <svg 
    className = "SlidePrevBtn"
    width = "13"
    height = "20"
    viewBox = "0 0 13 20"
    fill = "none"
    xmlns = "http://www.w3.org/2000/svg" 
  >
  <path 
    d = "M12.7031 2.34375L5.04687 10L12.7031 17.6563L10.3594 20L0.359373 10L10.3594 1.45477e-06L12.7031 2.34375Z"
    fill = "white" 
  />
  </svg>

);

export default SlidePrevBtn;