import React from 'react';

const ArrowTop = () => (
  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6563 12.7031L10 5.04688L2.34375 12.7031L5.09169e-06 10.3594L10 0.359381L20 10.3594L17.6563 12.7031Z"
          fill="#161616"/>
  </svg>

)

export default ArrowTop;