import { v4 as uuidv4 } from "uuid";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import VideoStableOutlinedIcon from "@mui/icons-material/VideoStableOutlined";
import PhotoSizeSelectSmallOutlinedIcon from "@mui/icons-material/PhotoSizeSelectSmallOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const adminNavList = [
  {
    id: uuidv4(),
    icon: <DescriptionOutlinedIcon />,
    to: "/",
    name: "All Products",
  },
  {
    id: uuidv4(),
    to: "/users",
    icon: <PersonOutlineOutlinedIcon />,
    name: "Users",
  },
  {
    id: uuidv4(),
    to: "/slider",
    icon: <VideoStableOutlinedIcon />,
    name: "Slides",
  },
  {
    id: uuidv4(),
    icon: <PhotoSizeSelectSmallOutlinedIcon />,
    to: "/add-products",
    name: "Add Product",
  },
];
