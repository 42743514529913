import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allProducts: [],
  language: "",
  status: "idle",
};

const allProductsSlice = createSlice({
  name: "allProducts",
  initialState,
  reducers: {
    getAllProducts: (state, action) => {
      state.allProducts = action.payload;
    },
    getLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allProductsAsync.pending, (state) => {
  //       state.status = "loading";
  //     })
  //     .addCase(allProductsAsync.fulfilled, (state, action) => {
  //       state.status = "idle";
  //       state.productItem = action.payload;
  //     });
  // },
});

export const { getAllProducts, getLanguage } = allProductsSlice.actions;

export default allProductsSlice.reducer;

export const selectAllProducts = (state) => state.allProducts.allProducts;
export const selectLanguage = (state) => state.allProducts.language;
