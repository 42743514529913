import React, { useState } from "react";
import { auth, db } from "../../firebase/firebase";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import GoogleIcon from "../../assets/icons/GoogleIcon";
import PassEyeIcon from "../../assets/icons/PassEyeIcon";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/authUser/authSlice";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "@firebase/firestore";
import { useTranslation } from "react-i18next";
import Circular from "../Circular";
import axiosInstance from "../../axios/axiosInstance";

function SignIn({ onClose, toggleAuthMode, toggleReset }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const provider = new GoogleAuthProvider();
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const {
    signInText,
    emailText,
    passwordText,
    logIn,
    forgotPassword,
    or,
    noAccount,
    signUpText,
  } = t("auth.signIn");
  const handleTogglePassword = () => {
    setshowPassword(!showPassword);
  };
  const handleAddEmail = (e) => {
    setEmail(e.target.value);
    setIsError(false);
    setIsEmpty(false);
  };

  const handleAddPassword = (e) => {
    setPassword(e.target.value);
    setIsError(false);
    setIsEmpty(false);
  };

  const signIn = async (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      setIsEmpty(true);
      return;
    }

    try {
      setIsAuthLoading(true);

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;

      dispatch(
        setUser({
          email: user.email,
          uid: user.uid,
          isAuth: true,
        }),
      );

      setIsError(false);
      setIsEmpty(false);
      onClose();
      setEmail("");
      setPassword("");
      setIsAuthLoading(false);

      navigate("/");
    } catch (error) {
      setIsAuthLoading(false);
      setIsError(true);
      setIsEmpty(false);
    }
  };

  const postUserData = async (user) => {
    try {
      await axiosInstance.post("/users", {
        authUserId: user?.uid,
        name: user?.displayName ? user.displayName : "",
        imageUrl: user?.photoURL ? user.photoURL : "",
        email: user.email,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      setIsAuthLoading(true);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      dispatch(
        setUser({
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
        }),
      );

      await setDoc(doc(db, "users", user.email), {
        email: user.email,
        displayName: user.displayName,
        photo: user.photoURL,
        uid: user.uid,
        isAuth: true,
      });
      await postUserData(user);

      // onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsAuthLoading(false);
    }
  };

  return (
    <div className="signIn_panel">
      <h2>{signInText}</h2>
      <form className="form" name="signIn" onSubmit={signIn}>
        <label className="input_box">
          <p className="input-box_title">{emailText}</p>
          <input
            name="email"
            type="email"
            className={isEmpty ? "text-input text-input_error" : "text-input"}
            autoComplete="current-email"
            value={email}
            onChange={(e) => handleAddEmail(e)}
          />
          {isError && (
            <p className="error-text">Username or password incorrect.</p>
          )}
          {isEmpty && <p className="error-text">Please enter email.</p>}
        </label>
        <label className="input_box">
          <p className="input-box_title">{passwordText}</p>
          <div className="passEye">
            <input
              className={isEmpty ? "text-input text-input_error" : "text-input"}
              name="password"
              value={password}
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              onChange={(e) => handleAddPassword(e)}
            />
            <span className="eye" onClick={handleTogglePassword}>
              <PassEyeIcon />
            </span>
            {isError && (
              <p className="error-text">Username or password incorrect.</p>
            )}
            {isEmpty && <p className="error-text">Please enter password.</p>}
          </div>
        </label>
        <div className="reset_link">
          <p onClick={toggleReset} className="reset_link">
            {forgotPassword}
          </p>
        </div>
        <button type="submit" className="logIn">
          {logIn}
        </button>
      </form>

      <div className="icons">
        <div className="line">
          <hr />
          <div className="or">{or}</div>
          <hr />
        </div>
        <ul className="icons_ul">
          <li className="icons_url" onClick={signInWithGoogle}>
            <GoogleIcon />
          </li>
          {/*<li className="icons_url" onClick={signInWithFacebook}>*/}
          {/*  <FacebookIconAuth/>*/}
          {/*</li>*/}
        </ul>
        <div className="signIn_footer">
          <p>
            {" "}
            {noAccount} <span onClick={toggleAuthMode}>{signUpText}</span>
          </p>
        </div>
      </div>
      {isAuthLoading && <Circular />}
    </div>
  );
}

export default SignIn;
