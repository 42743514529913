import React from "react";
import NavigateSection from "../components/NavigateSection";
import NavigateFrame from "../components/NavigateFrame";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  const { header, inner, text_1, text_2, text_3, text_4, text_5 } =
    t("aboutUs");

  return (
    <section className="section_padding">
      <NavigateSection to={"/about_us"} header={header} link={header} />
      <NavigateFrame title={inner} />
      <div className="about_text">
        <p>{text_1}</p>
        <p>{text_2}</p>
        <p>{text_3}</p>
        <p>{text_4}</p>
        <p>{text_5}</p>
      </div>
    </section>
  );
};

export default About;
