import React, { useState } from "react";
import { auth } from "../../firebase/firebase";
import { signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { removeUser } from "../../features/authUser/authSlice";
import Circular from "../Circular";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { removeFavorite } from "../../features/favorites/favoritesSlice";

const SignOut = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logOutText, cancel, signOutText, text } = t("auth.logOut");
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const userSignOut = async () => {
    setIsAuthLoading(true);
    await signOut(auth)
      .then(() => {
        dispatch(removeUser());
        dispatch(removeFavorite());
        onClose();
        navigate("/");
      })
      .catch((error) => console.error("Error signing out:", error));
    setIsAuthLoading(false);
  };

  return (
    <div className="sign-out">
      <div className="log-out">
        <h4>{logOutText}</h4>
        <span onClick={onClose}>X</span>
      </div>
      <h4 className="sign-out_text">{text}</h4>
      <div className="input_box">
        <button className="sign-out_btn cancel-btn" onClick={onClose}>
          {cancel}
        </button>
        <button className="sign-out_btn" onClick={userSignOut}>
          {signOutText}
        </button>
      </div>
      {isAuthLoading && <Circular />}
    </div>
  );
};

export default SignOut;
