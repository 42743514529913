import { v4 as uuidv4 } from "uuid";

export const paymentMethods = [
  {
    id: uuidv4(),
    // image: null,
    title: "Cash On Delivery",
    // name: 'Cash on delivery',
  },
  // {
  //   id: uuidv4(),
  //   image: iDram,
  //   // name: 'Pay With',
  //   title: 'Pay With Idram',
  // },
  // {
  //   id: uuidv4(),
  //   image: telCell,
  //   // name: 'Pay With',
  //   title: 'Pay With TelCell'
  // },
];
