import React from "react";
import SlidePrevBtn from "../assets/icons/SlidePrevBtn";
import SlideNextBtn from "../assets/icons/SlideNextBtn";
import ArrowBottom from "../assets/icons/ArrowBottom";
import ArrowTop from "../assets/icons/ArrowTop";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <SlidePrevBtn />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <SlideNextBtn />
    </div>
  );
}

export const ourClientSettings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "0px",
  slidesToShow: 1,
  speed: 500,
  prevArrow: <NextArrow />,
  nextArrow: <PrevArrow />,
  dots: true,
};

export const specialSettings = {
  dots: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  infinite: true,
  centerMode: true,
  centerPadding: "50px",
  className: "special-Slider_box",
  autoplay: true,
  speed: 2000,
  initialSlide: 0,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1320,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1060,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 700,
      settings: {
        // dots: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        // arrows: false,
        vertical: true,
        // infinite: true,
        verticalSwiping: true,
        autoplay: false,
        speed: 2000,
        centerMode: false,
      },
    },
  ],
};

export const ourPartnerSettings = {
  className: "center",
  dots: true,
  centerMode: true,
  infinite: true,
  centerPadding: "0px",
  slidesToShow: 6,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  // speed: 5000,
  // autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
        prevArrow: false,
        nextArrow: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const SinglePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="arrowPrev" onClick={onClick}>
      <ArrowBottom />
    </button>
  );
};

export const SingleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="arrowNext" onClick={onClick}>
      <ArrowTop />
    </button>
  );
};

export const productItemSlider = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  arrows: false,
  swipeToSlide: false,
  responsive: [
    {
      breakpoint: 400,
      settings: {
        swipeToSlide: true,
        dots: true,
      },
    },
  ],
};
export const productItemSmallSlider = {
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  focusOnSelect: true,
  vertical: true,
  infinite: false,
  prevArrow: <SinglePrevArrow />,
  nextArrow: <SingleNextArrow />,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        vertical: false,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 798,
      settings: {
        vertical: false,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 470,
      settings: {
        vertical: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const cartSlider = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
