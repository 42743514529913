import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Main from "../../pages/Main";
import Home from "../../pages/Home";
import About from "../../pages/About";
import Categories from "../../pages/Categories";
import Contact from "../../pages/Contact";
import Accessories from "../../pages/Accessories";
import Flowers from "../../pages/Flowers";
import Decorations from "../../pages/Decorations";
import Cart from "../../pages/Cart";
import Payment from "../../pages/Payment";
import Favorites from "../Favorites";
import ProductsItem from "../products/ProductsItem";
import NotFound from "../../pages/NotFound";
import axiosInstance from "../../axios/axiosInstance";
import {
  getAllProducts,
  selectLanguage,
} from "../../features/allProducts/allProducts";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "../../features/authUser/authSlice";
import {
  selectFavorites,
  toggleFavorites,
} from "../../features/favorites/favoritesSlice";

const Public = () => {
  const dispatch = useDispatch();
  const lang = useSelector(selectLanguage);
  const userId = useSelector(selectUserId);
  const favorite = useSelector(selectFavorites);
  const allProducts = async () => {
    try {
      const response = await axiosInstance.get(`/products`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      const data = response.data;
      dispatch(getAllProducts(data));
    } catch (err) {
      console.error("Error fetching products:", err.message);
    }
  };
  useEffect(() => {
    allProducts();

    return () => allProducts();
  }, [lang]);

  const fetchFavorites = async () => {
    try {
      const userDataResponse = await axiosInstance.get(`/users/${userId}`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      const favorites = userDataResponse?.data.data.products;
      dispatch(toggleFavorites(favorites));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchFavorites();
    }
  }, [lang, userId]);

  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/categories/accessories" element={<Accessories />} />
        <Route path="/categories/flowers" element={<Flowers />} />
        <Route path="/categories/decorations" element={<Decorations />} />
        <Route path="/cart/basket" element={<Cart />} />
        <Route path="/cart/basket/payment" element={<Payment />} />
        <Route path="/favourites" element={<Favorites />} />
        <Route
          path="/categories/:categoryName/:productID"
          element={<ProductsItem />}
        />
        <Route
          path="/categories/:productName?page=:number"
          element={<ProductsItem />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default Public;
