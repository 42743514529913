import React from "react";
import NavigateSection from "../components/NavigateSection";
import {categories} from "../utils/categories";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Categories = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {name} = t('main.ourProduct')

  const handleNavigate = (link) => {
    navigate(`${link}`)
  }
  return (
    <section className='section_padding'>
      <NavigateSection header={t('categories')} link={t('categories')}/>

      <div className='categories'>
        <div className="categories-img-block">
          {categories.map((item, index) => (
            <div
              key={item.id}
              className="our-products-img-item"
              onClick={() => handleNavigate(item.link)}
            >
              <img src={item.img} alt={item.title} className="categories-img"/>
              <p className="our-products-text">{name[index]} {item.icon}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Categories;
