import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { specialSettings } from "../utils/sliderSettings";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectAllProducts,
  selectLanguage,
} from "../features/allProducts/allProducts";
import Item from "./products/Item";

const SpecialOrder = () => {
  const allProducts = useSelector(selectAllProducts);
  const [specialOrders, setSpecialOrders] = useState([]);
  const lang = useSelector(selectLanguage);
  const { t } = useTranslation();
  const { title } = t("main.popularOrders");

  useEffect(() => {
    const filteredProds = allProducts?.items?.products?.filter((item) => {
      return item.subCategories.includes("Popular");
    });
    if (filteredProds) {
      setSpecialOrders(filteredProds);
    }
  }, [lang, allProducts]);

  return (
    <div className="special-order">
      <div className="special-order_title">
        <span className="title-header">{title}</span>
      </div>
      <div className="special-order-block-bg">
        <Slider {...specialSettings}>
          {specialOrders.map((item) => (
            <Item
              item={item}
              lang={lang}
              key={item.uuid}
              homePage={true}
              category={item.category}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SpecialOrder;
