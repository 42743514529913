import { Dialog, styled } from "@mui/material";

export const UserAuth = styled(Dialog)({
  position: "absolute",
  right: "20px",
  top: "14%",
  left: "auto",
  border: "none",
  background: "transparent",
  overflow: "auto",
  borderRadius: "4px",
  outline: "none",
  padding: "20px",
  "& .MuiDialog-paper": {
    backgroundColor: "transparent",
    width: "auto",
    margin: 0,
  },
  "& .MuiDialog-container": {
    height: "auto",
  },
  "@media(max-width:600px)": {
    width: "100%",
    left: 0,
    right: 0,
  },
});
