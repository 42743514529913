import { styled } from "@mui/material";

export const AllProdBox = styled("div")({
  maxWidth: "100%",
  width: "100%",
  minHeight: "600px",
  borderRadius: "15px",
  backgroundColor: "#fff",
  // padding: "35px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});
export const AllCategoriesBox = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});
