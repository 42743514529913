import React from 'react';

const WhoWeAreIcons = () => (
  <svg width="1000" height="100%" viewBox="0 0 1643 560" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="41" height="42" transform="matrix(-0.790225 0.612817 0.612817 0.790225 160.399 158.742)"
          fill="#F56E91"/>
    <rect width="35.9665" height="36.8438" transform="matrix(0.85571 -0.517456 -0.517456 -0.85571 993.982 469.529)"
          fill="#EDEDED"/>
    <rect width="25.7881" height="23.0605" transform="matrix(0.0756031 0.997138 0.997138 -0.0756031 0 469.486)"
          fill="#B0D404"/>
    <rect width="24.8254" height="29.5304" transform="matrix(0.163763 0.9865 0.9865 -0.163763 1243 534.578)"
          fill="#F56E91"/>
    <rect width="24.6642" height="25.2658" transform="matrix(-0.86587 -0.500269 -0.500269 0.86587 1643 480.081)"
          fill="#B0D404"/>
    <rect width="16.7086" height="17.1161" transform="matrix(0.808999 -0.58781 -0.58781 -0.808999 1520.03 23.6685)"
          fill="#F56E91"/>
  </svg>

)

export default WhoWeAreIcons;