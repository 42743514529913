import React, { useEffect, useState } from "react";
import "./assets/styles/main.scss";
import { useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { removeUser, setUser } from "./features/authUser/authSlice";
// import ProductBuying from "./pages/ProductBuying";
import { auth } from "./firebase/firebase";
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Public from "./components/auth/Public";
import Private from "./components/auth/Private";
import { LinearProgress } from "@mui/material";
import { removeProductForBuying } from "./features/products/productSlice";

function App() {
  const dispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoading(false);
      if (user) {
        dispatch(
          setUser({
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
            photoURL: user.photoURL,
            isAuth: true,
          }),
        );
        if (user.uid === process.env.REACT_APP_FIREBASE_ADMIN) setIsAdmin(true);
      } else {
        dispatch(removeUser());
        // dispatch(removeFavorite());
        dispatch(removeProductForBuying());
        setIsAdmin(false);
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return isAdmin ? <Private /> : <Public />;
}

export default App;
