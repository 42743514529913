import axiosInstance from "../../axios/axiosInstance";
import { toggleFavorites } from "../../features/favorites/favoritesSlice";

export const axiosToggleFavorite = async (item, userId, favorite, dispatch) => {
  const isFavorite = favorite.some((e) => e.uuid === item.uuid);

  try {
    if (isFavorite) {
      await axiosInstance.patch(`/users/favorite`, {
        authUserId: userId,
        productId: item.uuid,
      });
    } else {
      await axiosInstance.post(`/users/favorite`, {
        authUserId: userId,
        productId: item.uuid,
      });
    }

    dispatch(toggleFavorites(item));
  } catch (error) {
    console.error("Error toggling favorites:", error);
  }
};
