import {v4 as uuidv4} from "uuid";

export const menuList = [
  {
    id: uuidv4(),
    to: "/",
    // name: "Home",
  },
  {
    id: uuidv4(),
    to: "/about",
    // name: "About",
  },
  {
    id: uuidv4(),
    to: "/categories",
    // name: "Categories",
  },
  {
    id: uuidv4(),
    to: "/contact",
    // name: "Contact",
  },
];

