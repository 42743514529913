import React from "react";

import whoWeAreImg from "../assets/pic/who-we-are.jpg";
import WhoWeAreIcons from "../assets/icons/WhoWeAreIcons";
import {useTranslation} from "react-i18next";

const WhoWeAre = () => {
  const {t} = useTranslation()
  const {title, text, info} = t('main.whoWeAre')

  return (
    <div className="who-we-are-body">
      <span className='style-svg'>
        <WhoWeAreIcons/>
      </span>
      <div className="who-we-are-info-block">
        <div className="who-we-are-img-block">
          <img src={whoWeAreImg} alt="Whoweareimg" className="Whoweareimg"/>
        </div>
        <div className="who-we-are-text-block">
          <div className="who-we-are-text-info">
            <h3 className="who-we-are-title">{title}</h3>
            <p className="who-we-are-text">
              {text}
            </p>
            <p className="who-we-are-description">
              {info}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}


export default WhoWeAre;
