import React, { useState } from "react";
import { menuList } from "../utils/menuList";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BurgerMenu = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (event) => {
    setIsChecked((checked) => !checked);
  };
  const handleClose = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="burger_menu">
        <input
          id="toggleChecker"
          type="checkbox"
          onChange={handleChange}
          checked={isChecked}
        />
        <label id="togglerLable" htmlFor="toggleChecker">
          <div className="checkboxtoggler">
            <div className="line-1"></div>
            <div className="line-2"></div>
            <div className="line-3"></div>
          </div>
        </label>
      </div>
      {isChecked && (
        <main className="web_main">
          {menuList.map((link, index) => (
            <NavLink
              key={link.id}
              to={link.to}
              onClick={handleClose}
              className={({ isActive }) => (isActive ? "active" : "link")}
            >
              {t("main.header_menu")[index]}
            </NavLink>
          ))}
        </main>
      )}
    </>
  );
};

export default BurgerMenu;
