import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getLanguage } from "../features/allProducts/allProducts";

const languages = [
  { code: "hy", lang: "ՀԱՅ" },
  { code: "ru", lang: "РУС" },
  { code: "en", lang: "ENG" },
];
const Languages = () => {
  const { i18n } = useTranslation();
  const [checkedLanguage, setCheckedLanguage] = useState("hy");
  const dispatch = useDispatch();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
    setCheckedLanguage(lng);
    dispatch(getLanguage(checkedLanguage));
  };

  useEffect(() => {
    dispatch(getLanguage(checkedLanguage));
  }, [changeLanguage, checkedLanguage]);

  return (
    <div className="header_languages">
      {languages.map((lng) => {
        return (
          <button
            className={lng.code === i18n.language ? "selected" : ""}
            key={lng.code}
            onClick={() => changeLanguage(lng.code)}
          >
            {lng.lang}
          </button>
        );
      })}
    </div>
  );
};

export default Languages;
