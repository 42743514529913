import React, { useState } from "react";
import { auth } from "../../firebase/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import Arrow from "../../assets/icons/Arrow";
import { useTranslation } from "react-i18next";
import Circular from "../Circular";

const ResetPassword = ({ onClose, onAuthSuccess, toggleReset }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const handleReset = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmptyEmail(true);
      return;
    }
    try {
      setIsLoading(true);
      await sendPasswordResetEmail(auth, email);
      setIsLoading(false);
      onClose();
    } catch (err) {
      console.error("Password reset failed", err);
      setError(true);
      if (err.code === "auth/user-not-found") {
        setEmailError(true);
      }
    }
  };

  const handleNavigate = () => {
    toggleReset();
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(false);
    setEmptyEmail(false);
  };

  return (
    <div className="reset">
      <div className="reset_title">
        <span className="reset_title-arrow" onClick={handleNavigate}>
          <Arrow />
        </span>
        <h2>{t("auth.reset.resetTitle")}</h2>
      </div>

      <div className="input_box">
        <p className="reset_title">{t("auth.signIn.emailText")}</p>
        <form onSubmit={handleReset}>
          {isLoading ? (
            <Circular />
          ) : (
            <>
              <div className="input-box">
                <input
                  className={
                    error || emptyEmail
                      ? "input_reset input_reset-error"
                      : "input_reset"
                  }
                  name="input_reset"
                  type="email"
                  // placeholder="Enter your email"
                  value={email}
                  onChange={(e) => handleChange(e)}
                />
                {(emailError || error) && (
                  <p className="reset-error">
                    Email not found. Please enter a correct email.
                  </p>
                )}
                {emptyEmail && (
                  <p className="reset-error">Please enter email</p>
                )}
              </div>
              <button className="btn_reset" type="submit">
                {t("auth.reset.resetBtn")}
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
