import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Pagination } from "@mui/material";
import NavigateSection from "./NavigateSection";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { cartSlider } from "../utils/sliderSettings";
import { useTranslation } from "react-i18next";
import {
  selectProductForBuying,
  setProductDescription,
} from "../features/products/productSlice";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axios/axiosInstance";
import { selectUserId } from "../features/authUser/authSlice";
import { selectLanguage } from "../features/allProducts/allProducts";
import AuthModal from "./auth/AuthModal";
import { addToOrder } from "../utils/Axios/addToOrder";
import {
  selectFavorites,
  toggleFavorites,
} from "../features/favorites/favoritesSlice";

const Favorites = () => {
  const dispatch = useDispatch();
  const lang = useSelector(selectLanguage);
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState([]);
  const favorite = useSelector(selectFavorites);
  const authUserId = useSelector(selectUserId);
  const buyingProduct = useSelector(selectProductForBuying);

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const totalPages = Math.ceil(favorites.length / itemsPerPage);
  const [open, setOpen] = useState(false);

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const visibleItems = favorites.slice(firstIndex, lastIndex);
  const [updated, setUpdated] = useState(false);
  const { emptyTitle, emptyText, header, favLink } = t("favourites");
  const { currency } = t("payment");

  const handleClose = () => {
    setOpen(false);
  };

  const addProducts = async (e, item) => {
    e.preventDefault();

    if (!authUserId) {
      setOpen(true);
      return;
    }
    try {
      await addToOrder(item, authUserId, dispatch, buyingProduct);
    } catch (e) {
      console.log(e);
    }
  };
  const handleRemove = async (e, item) => {
    e.preventDefault();

    await axiosInstance.patch(`/users/favorite`, {
      authUserId: authUserId,
      productId: item.uuid,
    });
    const updatedFavorites = favorites.filter(
      (favItem) => favItem.uuid !== item.uuid,
    );
    setFavorites(updatedFavorites);
    dispatch(toggleFavorites(updatedFavorites));
  };

  const fetchUserData = async () => {
    const userDataResponse = await axiosInstance.get(`/users/${authUserId}`, {
      headers: {
        "Accept-Language": lang,
      },
    });
    const userData = userDataResponse.data.data.products;
    setFavorites(userData);
  };

  useEffect(() => {
    fetchUserData();
  }, [authUserId, dispatch]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleProductItemClick = (item) => {
    dispatch(
      setProductDescription({
        ...item,
        price: item.price,
      }),
    );
    navigate(`/categories/${item.category}/${item.uuid}`);
  };

  return (
    <section className="section_padding">
      <NavigateSection header={header} link={favLink} />
      {favorites.length ? (
        <div className="favorites_title">
          <div className="products_data">
            {visibleItems.map((item) => (
              <div className="data-frame" key={item.uuid}>
                <Slider {...cartSlider}>
                  {item.images?.map((url, index) => (
                    <div key={index}>
                      <img
                        src={url.url}
                        alt={item.uuid}
                        className="data_img"
                        onClick={() => handleProductItemClick(item)}
                      />
                    </div>
                  ))}
                </Slider>
                <div className="cart-frame">
                  <h4 className="data_title">
                    {item.name.length < 19
                      ? item.name
                      : item.name.slice(0, 16) + " ..."}
                  </h4>
                  <div className="data_price">
                    <span className="data_discount">
                      {item.discountPrice} {currency}
                    </span>
                    <span className="data_count">
                      {item.price} {currency}
                    </span>
                  </div>
                  <div className="data_buttons">
                    <button
                      className={
                        buyingProduct.some(
                          (product) =>
                            product.orderProducts[0].product.uuid === item.uuid,
                        )
                          ? "data_added-button"
                          : "data_add-button"
                      }
                      onClick={(e) => addProducts(e, item)}
                    >
                      {buyingProduct.some(
                        (product) =>
                          product.orderProducts[0].product.uuid === item.uuid,
                      )
                        ? "Added"
                        : "Add"}
                    </button>
                    <button
                      className="data_remove-button"
                      onClick={(e) => handleRemove(e, item)}
                    >
                      Remove
                    </button>
                  </div>

                  {/*-------------------------------------------*/}
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      ) : (
        <div className="empty-favorites">
          <div className="empty-favorites_inner">
            <h3>{emptyTitle}</h3>
            <p>{emptyText}</p>
          </div>
          <FavoriteBorderIcon
            sx={{
              fill: "#E9E9E9",
              maxWidth: "100%",
              fontSize: "400px",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              position: "relative",
            }}
          />
        </div>
      )}

      {open && <AuthModal open={open} onClose={handleClose} />}
    </section>
  );
};

export default Favorites;
