import React, { useEffect, useState } from "react";
import NavigateSection from "../NavigateSection";
import { useTranslation } from "react-i18next";
import FilterForProduct from "./FilterForProduct";
import Item from "./Item";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../features/allProducts/allProducts";
import { productList } from "../../utils/listItems";
import axiosInstance from "../../axios/axiosInstance";
import { Pagination, PaginationItem } from "@mui/material";
import { Link } from "react-router-dom";

const Products = ({ categoryName, category }) => {
  const { t } = useTranslation();
  const [subCategories, setSubCategories] = useState([]);
  const [activeItem, setActiveItem] = useState("All");
  const [listItems, setListItems] = useState([]);
  const [categoriesList, setCategoriesList] = useState("");
  const categoriesPlaceholder = t("categoriesPlaceholder");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchString, setSearchString] = useState("");
  const lang = useSelector(selectLanguage);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(1);

  useEffect(() => {
    switch (category) {
      case "Flowers":
        setCategoriesList(t("categoriesFilterFlowers"));
        setListItems(productList.listItemsFlowers);
        break;
      case "Accessories":
        setCategoriesList(t("categoriesFilterAccessories"));
        setListItems(productList.listItemsAccessories);
        break;
      case "Decorations":
        setCategoriesList(t("categoriesFilterDecorations"));
        setListItems(productList.listItemsDecorations);
        break;
      default:
        setCategoriesList("");
        setListItems([]);
    }
  }, [category, lang]);

  const fetchProd = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`/products`, {
        params: {
          searchString: searchString || "",
          category,
          page: currentPage,
          subCategories: subCategories,
        },
        headers: {
          "Accept-Language": lang,
        },
      });

      const data = response.data;
      setProducts(data?.items?.products || []);
      setCurrentPage(data?.currentPage || 1);
      setTotalPage(data?.totalItems);
      setPageSize(data?.pageSize);

      setIsLoading(false);
      // setFilteredProducts(data.items.products);
    } catch (err) {
      console.error("Error fetching products:", err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProd();
  }, [searchString, lang, currentPage, subCategories]);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // navigate(`/${lang}/page-${currentPage}/`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSubCategories = (item) => {
    setSubCategories(item === "All" ? [] : item);
    setActiveItem(item);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchString(searchQuery);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (!value) {
      setSearchString("");
    }
  };

  return (
    <section className="section_padding">
      <NavigateSection
        header={categoryName}
        lang={lang}
        link={categoryName}
        categories={t("categories")}
        setSubCategories={setSubCategories}
      />
      {!isLoading && (
        <div className="products">
          <FilterForProduct
            listItems={listItems}
            handleSubCategories={handleSubCategories}
            activeItem={activeItem}
            categoriesList={categoriesList}
            handleSearchSubmit={handleSearchSubmit}
            handleChange={handleChange}
            searchQuery={searchQuery}
            categoriesPlaceholder={categoriesPlaceholder}
          />
          <div className="products_data">
            {products.map((item) => (
              <Item
                key={item.uuid}
                item={item}
                lang={lang}
                homePage={false}
                category={category}
              />
            ))}
          </div>

          {products.length > 0 && (
            <div className="pagination">
              <Pagination
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`${item.page === 1 ? "" : `?page=${item.page}`}`}
                    {...item}
                  />
                )}
                count={Math.ceil(totalPage / pageSize)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Products;
