import React, { useState } from "react";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  ProductBox,
  ProductTabs,
} from "../../../../assets/adminStyles/addProduct/addProducts";
import {
  AdminHeader,
  Section,
} from "../../../../assets/adminStyles/user/users";
import AdminAllProducts from "./AdminAllProducts";
import LanguageTab, { a11yProps } from "../../languageTab/LanguageTab";

const AdminAllLangProds = () => {
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Section>
      <AdminHeader>All Product</AdminHeader>

      <ProductBox>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="ARM" value={0} {...a11yProps(0)} />
            <Tab label="RUS" value={1} {...a11yProps(1)} />
            <Tab label="ENG" value={2} {...a11yProps(2)} />
          </Tabs>
        </Box>
        <ProductTabs>
          <LanguageTab value={value} index={0}>
            <AdminAllProducts lang="hy" />
          </LanguageTab>
          <LanguageTab value={value} index={1}>
            <AdminAllProducts lang="ru" />
          </LanguageTab>
          <LanguageTab value={value} index={2}>
            <AdminAllProducts lang="en" />
          </LanguageTab>
        </ProductTabs>
      </ProductBox>
    </Section>
  );
};

export default AdminAllLangProds;
