import React from 'react';

const GlassIcon = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 20L13.6667 13.6667M15.7778 8.38889C15.7778 9.35921 15.5867 10.32 15.2153 11.2165C14.844 12.113 14.2997 12.9275 13.6136 13.6136C12.9275 14.2997 12.113 14.844 11.2165 15.2153C10.32 15.5867 9.35921 15.7778 8.38889 15.7778C7.41857 15.7778 6.45775 15.5867 5.56128 15.2153C4.66482 14.844 3.85028 14.2997 3.16416 13.6136C2.47803 12.9275 1.93377 12.113 1.56245 11.2165C1.19112 10.32 1 9.35921 1 8.38889C1 6.42923 1.77847 4.54984 3.16416 3.16416C4.54984 1.77847 6.42923 1 8.38889 1C10.3485 1 12.2279 1.77847 13.6136 3.16416C14.9993 4.54984 15.7778 6.42923 15.7778 8.38889Z"
      stroke="#555555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

)

export default GlassIcon;