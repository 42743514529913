import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelCart,
  getProductForBuying,
  getTotalPrice,
  setAddedProduct,
} from "../features/products/productSlice";
import NavigateSection from "../components/NavigateSection";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import EmptyCart from "../components/EmptyCart";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axiosInstance from "../axios/axiosInstance";
import { selectEmail, selectUserId } from "../features/authUser/authSlice";
import Circular from "../components/Circular";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector(selectUserId);
  const [count, setCount] = useState(1);
  const userEmail = useSelector(selectEmail);
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    yourCart,
    empty,
    basketItems,
    quantity,
    deliveryDate,
    remove,
    subtotal,
  } = t("basket");
  const { currency } = t("payment");

  const {
    basketContactNumber,
    contactNumber_placeholder,
    basketEmail,
    email_placeholder,
    basketAddress,
    address_placeholder,
    basketMessage,
    message_placeholder,
    cancelBtn,
    buyBtn,
  } = t("basket.form");

  useEffect(() => {
    const getBuyingItems = async () => {
      try {
        setIsLoading(true);
        const orderItemsResponse = await axiosInstance.get(
          `/users/${userId}/order`,
        );
        const orderItemsData = orderItemsResponse.data?.data;
        setIsLoading(false);
        setItems(orderItemsData);
        setEmail(userEmail);
        dispatch(getProductForBuying(orderItemsData));
      } catch {
        setIsLoading(false);
      }
    };
    getBuyingItems();
  }, [userId, dispatch]);

  const handleForBuying = async (e) => {
    e.preventDefault();

    try {
      let total = 0;
      setIsLoading(true);
      const axiosRequests = items.map(async (item) => {
        const name = item.name;
        const productId = item.orderProducts[0].product.uuid;
        const count = item.orderProducts[0].count;
        const deliveryDate = item.deliveryDate;
        const currentDate = new Date().toISOString();
        const subtotal = item.orderProducts[0].subtotal;
        total += subtotal;

        await axiosInstance.patch(`/users/${userId}/order/${item.id}`, {
          name,
          deliveryDate: deliveryDate || currentDate,
          orderItems: [
            {
              count,
              productId,
            },
          ],
          email,
          contactNumber,
          deliveryAddress: address,
          message: message,
        });
      });
      await Promise.all(axiosRequests);

      dispatch(getTotalPrice(total));
      setContactNumber("");
      setAddress("");
      setEmail("");
      setMessage("");

      !isLoading && navigate("/cart/basket/payment");
    } catch {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    dispatch(cancelCart());
    navigate("/");
  };

  const handleRemove = async (e, item) => {
    e.preventDefault();
    await axiosInstance.delete(`/users/${userId}/order/${item.id}`);
    const filteredItems = items.filter((e) => e.id !== item.id);
    setItems(filteredItems);
    dispatch(setAddedProduct(item));
  };

  const getDeliveryDate = (e, index) => {
    const newDate = new Date(e.target.value).toISOString();
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], deliveryDate: newDate };
    setItems(updatedItems);
  };

  const increment = (e, data, quantity) => {
    e.preventDefault();

    const updateQuantity = items.map((item) => {
      if (item.id === data.id) {
        const updatedOrderProducts = item.orderProducts?.map((orderProduct) => {
          setCount((prevCount) => prevCount + 1);
          return { ...orderProduct, count: orderProduct.count + 1 };
        });
        return { ...item, orderProducts: updatedOrderProducts };
      }
      return item;
    });
    setItems(updateQuantity);
  };

  const decrement = (e, data, count, quantity) => {
    e.preventDefault();
    const updateQuantity = items.map((item) => {
      if (item.id === data.id && count > 1) {
        const updatedOrderProducts = item.orderProducts?.map((orderProduct) => {
          setCount((prevCount) => prevCount - 1);
          return { ...orderProduct, count: orderProduct.count - 1 };
        });
        return { ...item, orderProducts: updatedOrderProducts };
      }
      return item;
    });

    setItems(updateQuantity);
  };

  useEffect(() => {
    const updateQuantity = items.map((item) => {
      const updatedOrderProducts = item.orderProducts?.map((orderProduct) => {
        return {
          ...orderProduct,
          subtotal: orderProduct.product.discountPrice * orderProduct.count,
        };
      });
      return { ...item, orderProducts: updatedOrderProducts };
    });
    setItems(updateQuantity);
  }, [count]);

  return (
    <section className="section_padding">
      <NavigateSection header={yourCart} link={basketItems} />
      {!isLoading ? (
        items.length ? (
          <div>
            <div className="cart_table-section">
              <form className="table_form">
                <Table className="cart_table">
                  <Thead className="cart_head">
                    <Tr className="cart_title">
                      <Th>{basketItems}</Th>
                      <Th>{quantity}</Th>
                      <Th>{subtotal}</Th>
                      <Th>{deliveryDate}</Th>
                      <Th>{remove}</Th>
                    </Tr>
                  </Thead>
                  <Tbody className="item-body">
                    {items?.map((item, index) => {
                      const orderProduct = item.orderProducts[0] || [];
                      const name = orderProduct?.product.name || "";
                      const orderID = item.id;
                      const count = orderProduct?.count || 1;
                      const subtotalPrice = orderProduct?.subtotal || 1;
                      const data = orderProduct?.product || {};
                      const deliveryDate = item.deliveryDate.split("T")[0];

                      return (
                        <Tr className="item" key={orderID}>
                          <Td className="item_description">
                            <div className="item_img">
                              <img src={data.imageURLs[0]} alt={name} />
                            </div>
                            <div className="item_title">
                              <p className="item_text">
                                {data.name.length < 19
                                  ? data.name
                                  : data.name.slice(0, 16) + " ..."}
                              </p>
                              <p className="item-count">
                                {" "}
                                {data?.discountPrice} {currency}
                              </p>
                            </div>
                          </Td>
                          <Td className="item_input">
                            <div className="md_item-title-quantity">
                              <div className="btn-group">
                                <button
                                  onClick={(e) => decrement(e, item, count)}
                                >
                                  -
                                </button>
                                <span className="item_quantityCount">
                                  {count}
                                </span>
                                <button
                                  onClick={(e) => increment(e, item, count)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </Td>
                          <Td className="item_subtotal">{subtotalPrice}</Td>
                          <Td className="item_date">
                            <input
                              type="date"
                              onChange={(e) => getDeliveryDate(e, index)}
                              value={deliveryDate || ""}
                            />
                          </Td>
                          <Td className="item_action">
                            <button onClick={(e) => handleRemove(e, item)}>
                              x
                            </button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </form>
            </div>
            <div className="input-title">
              <form onSubmit={handleForBuying}>
                <div className="input-group">
                  <div className="input-group_items">
                    <div className="input-inner-group">
                      <div className="input-item">
                        <label>{basketContactNumber}*</label>
                        <input
                          type="text"
                          placeholder={contactNumber_placeholder}
                          required
                          onChange={(e) => setContactNumber(e.target.value)}
                          value={contactNumber}
                        />
                      </div>
                      <div className="input-item">
                        <label>{basketEmail}</label>
                        <input
                          type="email"
                          value={email}
                          placeholder={email_placeholder}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="input-item">
                        <label>{basketAddress}*</label>
                        <input
                          type="text"
                          placeholder={address_placeholder}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                          value={address}
                        />
                      </div>
                    </div>
                    <div className="input-inner-group">
                      <div className="input-item">
                        <label>{basketMessage}</label>
                        <textarea
                          name="message"
                          cols="30"
                          rows="10"
                          placeholder={message_placeholder}
                          onChange={(e) => setMessage(e.target.value)}
                          value={message}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cart_btn-group">
                    <button className="cart_cancel" onClick={handleCancel}>
                      {cancelBtn}
                    </button>
                    <button className="cart_buy" type="submit">
                      {buyBtn}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <EmptyCart empty={empty} />
        )
      ) : (
        <Circular />
      )}
    </section>
  );
};

export default Cart;
