import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavigateSection = ({ link, header, categories, subLink, subName }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="navigation">
        <NavLink to={"/"}>{t("navigateName")}</NavLink>
        <span> > </span>
        {categories && (
          <>
            <NavLink to="/categories">{categories}</NavLink>
            <span> > </span>
          </>
        )}
        {link && (
          <NavLink
            to={subName && `/categories/${subLink}`}
            className={subName ? "" : "navigate_link-color"}
          >
            {link}
          </NavLink>
        )}

        {subName && (
          <>
            <span>></span>
            <span className="navigate_link-color"> {subName}</span>
          </>
        )}
      </div>
      <h2 className="navigation_title">{header}</h2>
    </>
  );
};

export default NavigateSection;
