import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const selectedLanguage = localStorage.getItem("selectedLanguage") || "hy";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnObjects: true,
    lng: selectedLanguage,
    fallbackLng: "hy",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      hy: {
        translation: {
          main: {
            header_menu: [
              "Գլխավոր",
              "Մեր Մասին",
              "Կատեգորիաներ",
              "Հետադարձ կապ",
            ],
            header_text: {
              text_1: "Զարդարեք ձեր կյանքը մեզ հետ",
              text_2: "Թող մենք լինենք ձեր կյանքի ծաղկեփնջի ծաղիկները",
              title: "ԱՆՎՃԱՐ ԱՌԱՔՈՒՄ",
              subtitle: "սկսած",
            },
            whoWeAre: {
              title: "Ով ենք մենք",
              text: "Ծաղիկների ուժը. ընդլայնել արարողությունների գեղեցկությունը Shine Flowers-ի փորձագիտական հպումով",
              info: "Ծաղկային կոմպոզիցիաները արվեստի մի ձև են, որը համատեղում է ծաղիկների գեղեցկությունը դիզայնի ստեղծագործության հետ: Անկախ նրանից՝ դուք պատրաստվում եք հարսանիք, կազմակերպում եք հատուկ միջոցառում, կամ պարզապես ցանկանում եք շքեղություն հաղորդել ձեր տանը, լավ մշակված ծաղկային կոմպոզիցիան կարող է մնայուն տպավորություն թողնել:",
            },
            discount: {
              priceText: "Off",
              text: "Այս շաբաթվա լավագույն գործարքները: Ընտրեք և վայելեք։",
            },
            ourProduct: {
              mainText: "ՄԵՐ ԱՊՐԱՆՔՆԵՐԸ",
              name: ["Ծաղիկներ", "Աքսեսուարներ", "Դեկորացիաներ"],
            },
            popularOrders: {
              title: "ՀԱՅՏՆԻ ՊԱՏՎԵՐՆԵՐ",
            },
            ourClientOptions: {
              title: "Ինչ են ասում մեր հաճախորդները",
              options: [
                {
                  text: "Ձեր թիմի ուշադրությունը դետալների նկատմամբ գերազանցեց բոլոր ակնկալիքները: Յուրաքանչյուր ծաղկաթերթ, յուրաքանչյուր մոմ, ամեն մի փոքրիկ դետալ կատարվեց անթերի: Մեր հարսանիքը կարծես երազ էր, այս ամենը ձեր փորձի շնորհիվ:",
                  author: "Սառա Կիրակոսյան",
                },
                {
                  text: "Այն միջավայրը, որը դուք ստեղծել եք ձեր ապշեցուցիչ ծաղկային կոմպոզիցիաներով և դեկորով, կատարյալ երանգ է ստեղծել մեր հատուկ օրվա համար: Վայրի վայր քայլելը նման էր ռոմանտիկ հրաշքների երկիր մտնելուն: Մենք հավերժ երախտապարտ ենք ձեր արվեստի համար:",
                  author: "Դավիթ և Էմիլի Ավետիսյաններ",
                },
                {
                  text: "Առաջին անգամ ծանոթանալու պահից մենք գիտեինք, որ լավ ձեռքերում ենք: Ձեր պրոֆեսիոնալիզմը, կրեատիվությունը և նվիրվածությունը կատարեցին հարսանիքի պլանավորման ողջ գործընթացը: Մեր հյուրերը դեռ հառաչում են շունչ կտրող ծաղիկներով և դեկորով:",
                  author: "Ռուանդայի ֆինանսների և էկոնոմիկայի նախարարություն",
                },
                {
                  text: "Բառերը չեն կարող արտահայտել, թե որքան երախտապարտ ենք այն անհավատալի աշխատանքի համար, որ ձեր թիմը կատարեց մեր հարսանիքին: Յուրաքանչյուր պայմանավորվածություն գլուխգործոց էր, և մեր տեսլականը պատկերելու ձեր կարողությունը գերազանցեց բոլոր սպասելիքները: Դուք իսկապես դարձրեցիք մեր օրը անմոռանալի:",
                  author: "Մասնավոր ընկերություն",
                },

                {
                  text: "Ձեր ընկերության հետ աշխատելը բացարձակ հաճույք էր: Ձեր թիմի կիրքը ձեր արածի հանդեպ փայլում է ամեն մանրուքով: Շնորհակալություն մեր գաղափարներն իրականություն դարձնելու և հարսանիքի օր ստեղծելու համար, որը դուրս էր մեր ամենավառ երազանքներից:",
                  author: "Մայքլ և Լիզա Ջոնսոն",
                },
              ],
            },
            contactUs: {
              contactUsText: {
                title: "Կապ մեզ հետ",
                text: "Shine Flowers & More-ում մենք առաջնահերթություն ենք տալիս բաց հաղորդակցությանը և հավատարիմ ենք մեր հաճախորդներին ակնառու ծառայություններ մատուցելուն: Անկախ նրանից, թե դուք ունեք հարցումներ, արձագանքներ կամ համագործակցության հնարավորություններ, մենք այստեղ ենք լսելու և օգնելու ձեզ ամեն քայլափոխի:",
                subtitleText:
                  "Մեր թիմը նվիրված է անհապաղ լուծելու ձեր կարիքները և ապահովելու, որ ձեր փորձը մեզ հետ բացառիկ չէ: Շնորհակալություն Shine Flowers & Events ընտրության համար:",
                subtitleInner: "Ազատորեն դիմեք մեզ ցանկացած հարցով:",
                contactInfo: {
                  contactTitle: "ԳՏԵՔ ՄԵՐ ԽԱՆՈՒԹԸ",
                  address:
                    "Առևտրի կենտրոն «Տաշիր Սթրիթ» Հյուսիսային պող., 6/2, Երևան 0001",
                  open: "Բաց է",
                  close: "Փակ է",
                },
              },
              formText: {
                formTitle: "Եկեք շփվենք",
                name: "Անուն",
                email: "Էլ․ Փոստ",
                phone: "Հեռախոս",
                ourProduct: "Մեր արտադրանքը",
                products: ["", "Ծաղիկներ", "Հարսանիքներ", "Դեկորացիաներ"],
                message: "Հաղորդագրություն",
                button: "Ուղարկել",
              },
            },
          },
          aboutUs: {
            header: "Մեր մասին",
            inner: "ՄԵԶ ԼԱՎԻՑ ՃԱՆԱՉԵՔ",
            text_1:
              "Բարի գալուստ Shine Flowers, որտեղ ծաղկային արվեստը հանդիպում է անզուգական էլեգանտության: Մուտք գործեք մեր ցնցող ծաղկային բուտիկներ և ընկղմվեք մթնոլորտում, որտեղ ծաղկում է գեղեցկությունը և ծաղկում է ստեղծագործությունը: Մեր հարգարժան կրեատիվ տնօրեն Լիլիթ Թոփչյանի գլխավորությամբ, մեր հմուտ ծաղկավաճառների թիմը արվեստագետների իսկական կիրքը յուրաքանչյուր ծաղկային ստեղծագործության մեջ:",
            text_2:
              "Shine Flowers-ում մենք ոգեշնչվում ենք դիզայնի, նորաձևության և ճարտարապետության վերջին միտումներից, ինչպես նաև յուրաքանչյուր ծաղկման բնածին գեղեցկությունից: Մեր ծաղկային ինստալյացիաները բնութագրվում են իրենց մաքուր գծերով, թարմ կոմպոզիցիաներով և ներդաշնակ հավասարակշռությամբ, անխափան կերպով: ներարկելով ժամանակակից նրբագեղություն ցանկացած տարածության մեջ:",
            text_3:
              "Մեր հաճախորդների ակնկալիքները հասկանալու և գերազանցելու նվիրվածությամբ՝ Shine Flowers-ը ստեղծել է տպավորիչ հաճախորդներ: Անկախ նրանից, թե դա խորապես անհատական դասավորություն է, շքեղ ծաղկային ինստալացիաներ կորպորատիվ միջավայրերի, մասնավոր միջոցառումների, նորաձևության ցուցադրությունների, շաբաթական ծաղկային պայմանագրերի կամ նուրբ գաղափարների համար: հարսանիքների համար մենք մեր ստեղծագործությունները հարմարեցնում ենք ամեն առիթի համար։",
            text_4:
              "Մեր հասանելիությունը տարածվում է նորաձևության, երաժշտության, միջոցառումների և կորպորատիվ ոլորտներում, քանի որ մենք տրամադրում ենք մեր ստեղծագործական փորձը՝ զարդարելու կորպորատիվ գրասենյակները, դիզայներական ցուցասրահները, շքեղ բուտիկները, ռեստորանները, բարերը և հյուրանոցները ողջ Հայաստանի տարածքում:",
            text_5:
              "Shine Flowers-ում մենք ոչ միայն ծաղիկներ ենք դասավորում, այլ մենք ստեղծում ենք փորձառություններ, հույզեր և տարածքներ փոխակերպում բնության հավերժական գեղեցկությամբ: Բացահայտեք ծաղկային էլեգանտության էությունը Shine Flowers-ի հետ, որտեղ յուրաքանչյուր ծաղկաթերթը պատմում է հիանալի պատմություն: արհեստագործություն և նուրբ արվեստ։",
          },
          categories: "Կատեգորիաներ",
          categoriesFilterFlowers: [
            "Բոլորը",
            "Հանրաճանաչ",
            "Կոմպոզիցիա",
            "Զամբյուղ",
            "Ծաղիկներ",
            "Դեկորացիաներ",
          ],
          categoriesFilterAccessories: [
            "Բոլորը",
            "Հանրաճանաչ",
            "Հարսանիքներ",
            "Մկրտություն",
            "Վալենտին",
            "Սուրբ Ծնունդ",
          ],
          categoriesFilterDecorations: [
            "Բոլորը",
            "Հանրաճանաչ",
            "Լուսանկարչական գոտիներ",
            "Վարձակալություն",
          ],
          categoriesPlaceholder: "Փնտրել",
          navigateName: "Գլխավոր Էջ",
          auth: {
            signIn: {
              signInText: "Մուտք",
              emailText: "Էլ․ փոստ",
              passwordText: "Գաղտնաբառ",
              logIn: "Մուտք գործել",
              forgotPassword: "Մոռացել եք Ձեր գաղտնաբառը?",
              or: "կամ",
              noAccount: "Չունե՞ք հաշիվ:",
              signUpText: "Գրանցվել:",
            },
            signUp: {
              text: "Գաղտնաբառը պետք է բաղկացած լինի 8 կամ ավելի նիշից և պարունակի առնվազն 1 թիվ և 1 հատուկ նիշ:",
              user: "Արդեն օգտատեր ե՞ք:",
            },
            reset: {
              resetTitle: "Վերականգնել գաղտնաբառը",
              resetBtn: "Վերականգնել",
            },
            logOut: {
              logOutText: "Դուրս գալ",
              cancel: "Չեղարկել",
              signOutText: "Դուրս գալ",
              text: "Իսկապե՞ս ուզում եք դուրս գալ",
            },
          },
          basket: {
            empty: "Ձեր զամբյուղը դատարկ է:",
            yourCart: "Ձեր զամբյուղը",
            basket: "Զամբյուղ",
            basketItems: "Զամբյուղ",
            quantity: "Քանակ",
            subtotal: "Ենթագումար",
            deliveryDate: "Առաքման օրը",
            remove: "Ջնջել",
            form: {
              basketContactNumber: "Կոնտակտային համարը",
              contactNumber_placeholder: "Մուտքագրեք ձեր համարը",
              basketEmail: "Էլ․ հասցե",
              email_placeholder: "Մուտքագրեք ձեր էլ․ հասցեն",
              basketAddress: "Հասցե",
              address_placeholder: "Մուտքագրեք առաքման հասցեն",
              basketMessage: "Հաղորդագրություն",
              message_placeholder: "Մուտքագրեք ձեր հաղորդագրությունը",
              cancelBtn: "Չեղարկել",
              buyBtn: "Գնիր հիմա",
            },
          },
          payment: {
            paymentHeader: "Վճարում",
            paymentMethod: "Վճարման եղանակ",
            cashOrCard: ["Կանխիկ առաքում", "Վճարիր", "Վճարիր"],
            myOrder: "Իմ պատվերը",
            orderSummary: "Պատվերի ամփոփում",
            subtotal: "Ենթագումար",
            delivery: "Առաքում",
            total: "Ընդամենը",
            cancel: "Չեղարկել",
            buy: "Գնել",
            currency: "Դրամ",
            free: "Անվճար",
            policy:
              "Ձեր անձնական տվյալները կօգտագործվեն ձեր պատվերը մշակելու, ձեր փորձառությունն աջակցելու համար այս կայքում և այլ նպատակների համար, որոնք նկարագրված են մեր Գաղտնիության քաղաքականության մեջ:",
            orderMessage: "Շնորհակալություն գնումների համար",
            errorMessage: "Խնդրում ենք կրկին փորձել",
          },
          favourites: {
            emptyTitle: "Հավաքեք և դիտեք ձեր սիրած նախընտրածները:",
            emptyText:
              "Նախշը որպես ձեր նախընտրած պահելու համար հպեք դրա կողքին գտնվող սրտի պատկերակին:",
            header: "Ձեր նախընտրած իրերը",
            favLink: "Նախընտրածներ",
          },
          btnGroup: {
            add: "Ավելացնել",
            added: "Ավեացված է",
          },
          footer: {
            text: "Գեղեցկություն ներարկելով ամեն պահի մեջ, մեր ծաղկային կոմպոզիցիաները մարմնավորում են նրբագեղություն և նրբագեղություն: Բարձրացրեք ձեր տարածքը մեր արհեստավոր ստեղծագործություններով, որոնք նախագծված են հմայելու և ոգեշնչելու համար, մեկ առ մեկ ծաղկում:",
            contactUs: "Կապ մեզ հետ",
            address: "Երևան 0001 Հյուսիսային պող. 6, 2",
            phone: "Հեռախոս",
            email: "Էլ. փոստ",
            newsLetter: "Լրատու",
            newsLetterText:
              "Բացառիկ ակցիաներ. Հետևե՛ք բացառիկ ակցիաներին և ֆլեշ վաճառքներին, որոնք հասանելի են միայն մեր հավատարիմ հաճախորդներին: Բաժանորդագրվեք մեր տեղեկագրին և հետևեք մեզ սոցիալական ցանցերում՝ տեղեկանալու վերջին առաջարկների և զեղչերի մասին:",
          },
        },
      },
      en: {
        translation: {
          main: {
            header_menu: ["Home", "About", "Categories", "Contact"],
            header_text: {
              text_1: "Decorate your life with us",
              text_2: "Let us be the blossoms in your life's bouquet",
              title: "FREE DELIVERY",
              subtitle: "from",
            },
            whoWeAre: {
              title: "Who we are",
              text: "The Power of Blooms: Enhancing the Beauty of Ceremonies with Shine Flowers' Expert Touch",
              info: "Flower arrangements are an art form that combines the beauty of flowers with the creativity of design. Whether you're planning a wedding, hosting a special event, or simply want to add a touch of elegance to your home, a well-crafted flower arrangement can make a lasting impression.",
            },
            discount: {
              priceText: "Off",
              text: "Best deals this week. Choose and enjoy.",
            },
            ourProduct: {
              mainText: "OUR PRODUCTS",
              name: ["Flowers", "Accessories", "Decorations"],
            },
            popularOrders: {
              title: "POPULAR ORDERS",
            },
            ourClientOptions: {
              title: "What Our Clients Say",
              options: [
                {
                  text: "Your team's attention to detail surpassed all expectations. Every petal, every candle, every tiny detail was flawlessly executed. Our wedding felt like a dream, all thanks to your expertise.",
                  author: "Sara Kirakosyan",
                },

                {
                  text: "The ambiance you created with your stunning floral arrangements and decor set the perfect tone for our special day. Walking into the venue was like stepping into a romantic wonderland. We're forever grateful for your artistry.",
                  author: "David and Emily Avetisyan's",
                },
                {
                  text: "From the moment we first met, we knew we were in good hands. Your professionalism, creativity, and dedication made the entire wedding planning process seamless. Our guests are still raving about the breathtaking flowers and decor!",
                  author: "Ministry of Finance and Economy, Rwanda",
                },
                {
                  text: "Words cannot express how thankful we are for the incredible work your team put into our wedding. Each arrangement was a masterpiece, and your ability to capture our vision exceeded all expectations. You truly made our day unforgettable.",
                  author: "Private Company",
                },

                {
                  text: "Working with your company was an absolute pleasure. Your team's passion for what you do shines through in every detail. Thank you for turning our ideas into reality and for creating a wedding day that was beyond our wildest dreams.",
                  author: "Michael and Lisa Johnson",
                },
              ],
            },
            contactUs: {
              contactUsText: {
                title: "Contact Us",
                text: "At Shine Flowers & Event we prioritize open communication and are committed to delivering outstanding service to our clients. Whether you have inquiries, feedback, or collaboration opportunities, we're here to listen and assist you every step of the way.",
                subtitleText:
                  "Our team is dedicated to promptly addressing your needs and ensuring your experience with us is nothing short of exceptional. Thank you for choosing Shine Flowers & MORE.",
                subtitleInner:
                  "Feel free to reach out to us with any questions.",
                contactInfo: {
                  contactTitle: "FIND OUR STORE",
                  address:
                    "Tashir Street Shopping Centre 6/2 Northern Ave, Yerevan 0001",
                  open: "Open now",
                  close: "Closed",
                },
              },
              formText: {
                formTitle: "Let’s talk",
                name: "Name",
                email: "Email",
                phone: "Phone",
                ourProduct: "Our Product",
                products: ["", "Flowers", "Weddings", "Decorations"],
                message: "Message",
                button: "Send",
              },
            },
          },
          aboutUs: {
            header: "About Us",
            inner: "KNOW US BETTER",
            text_1:
              "Welcome to Shine Flowers, where floral artistry meets unparalleled elegance. Step into our stunning floral boutiques and immerse yourself in an atmosphere where beauty flourishes and creativity blossoms. Led by our esteemed Creative Director, Lilit Topchyan, our team of skilled florists channels the true passion of artists into every floral creation.",
            text_2:
              "At Shine Flowers, we draw inspiration from the latest trends in design, fashion, and architecture, as well as the inherent beauty of each bloom. Our floral installations are characterized by their clean lines, fresh compositions, and harmonious balance, seamlessly infusing contemporary sophistication into any space.",
            text_3:
              "With a dedication to understanding and exceeding our customers' expectations, Shine Flowers has cultivated an impressive clientele. Whether it's a deeply personal arrangement, lavish floral installations for corporate settings, private events, fashion showcases, weekly floral contracts, or exquisite concepts for weddings, we tailor our creations to suit every occasion.",
            text_4:
              "Our reach extends across the realms of fashion, music, events, and corporate domains, as we lend our creative expertise to adorn corporate offices, design showrooms, luxury boutiques, restaurants, bars, and hotels throughout Armenia.",
            text_5:
              "At Shine Flowers, we don't just arrange flowers; we craft experiences, evoke emotions, and transform spaces with the timeless beauty of nature. Discover the essence of floral elegance with Shine Flowers, where every petal tells a story of exquisite craftsmanship and refined artistry.",
          },
          categories: "Categories",
          categoriesFilterFlowers: [
            "All",
            "Popular",
            "Composition",
            "Basket",
            "Flowers",
            "Decorations",
          ],
          categoriesFilterAccessories: [
            "All",
            "Popular",
            "Weddings",
            "Baptizing",
            "Valentine",
            "Christmas",
          ],
          categoriesFilterDecorations: [
            "All",
            "Popular",
            "Photo Zones",
            "Rental",
          ],
          categoriesPlaceholder: "Search",
          navigateName: "Home",
          auth: {
            signIn: {
              signInText: "Sign in",
              emailText: "Email",
              passwordText: "Password",
              logIn: "Log In",
              forgotPassword: "Forgot your password?",
              or: "or",
              noAccount: "No account?",
              signUpText: "Sign Up",
            },
            signUp: {
              text: "Password must be 8 or more characters and contain at least 1 number and 1 special character.",
              user: "Already a user?",
            },
            reset: {
              resetTitle: "Reset Password",
              resetBtn: "Reset",
            },
            logOut: {
              logOutText: "Log Out ?",
              cancel: "Cancel",
              signOutText: "Sign Out",
              text: "Are you sure want to log out?",
            },
          },
          basket: {
            empty: "You’r Card Is Empty.",
            yourCart: "Your Cart",
            basketItems: "Product",
            items: "Items",
            quantity: "Quantity",
            subtotal: "Subtotal",
            deliveryDate: "Delivery Date",
            remove: "Remove",
            form: {
              basketContactNumber: "Contact Number",
              contactNumber_placeholder: "Enter your number",
              basketEmail: "Email",
              email_placeholder: "Enter your email",
              basketAddress: "Address",
              address_placeholder: "Enter Delivery Address",
              basketMessage: "Message",
              message_placeholder: "Enter your Message",
              cancelBtn: "Cancel",
              buyBtn: "Buy Now",
            },
          },
          payment: {
            paymentHeader: "Payment",
            paymentMethod: "Payment Method",
            cashOrCard: ["Cash on delivery", "Pay With", "Pay With"],
            myOrder: "My Order",
            orderSummary: "Order Summary",
            subtotal: "Subtotal",
            delivery: "Delivery",
            total: "Total",
            cancel: "Cancel",
            buy: "Buy",
            currency: "AMD",
            free: "Free",
            policy:
              "Your Data Will Be Used To Process Your Order, Support Your Experience Throughout This Website, And For Other Purposes Described In Our ",
            orderMessage: "Thank you for order",
            errorMessage: "Please try again",
          },
          favourites: {
            emptyTitle: "Collect and view your favourite patterns.",
            emptyText:
              "To save a pattern as your favourite, tap the heart icon next to it.",
            header: "Your Favourites Items",
            favLink: "Favourites",
          },
          btnGroup: {
            add: "Add",
            added: "Added",
          },
          footer: {
            text: "Infusing beauty into every moment, our floral arrangements embody elegance and grace. Elevate your space with our artisanal creations, designed to enchant and inspire, one bloom at a time.",
            contactUs: "Contact Us",
            address: "6, 2 Northern Ave, Yerevan 0001",
            phone: "Phone",
            email: "E-mail",
            newsLetter: "NewsLetter",
            newsLetterText:
              "Exclusive Promotions: Be on the lookout for exclusive promotions and flash sales available only to our loyal customers. Subscribe to our newsletter and follow us on social media to stay updated on the latest offers and discounts.",
          },
        },
      },
      ru: {
        translation: {
          main: {
            header_menu: ["Дом", "о нас", "Категории", "Контакт"],
            header_text: {
              text_1: "Украсьте свою жизнь вместе с нами",
              text_2: "Позвольте нам стать цветами в букете вашей жизни.",
              title: "БЕСПЛАТНАЯ ДОСТАВКА",
              subtitle: "от",
            },
            whoWeAre: {
              title: "Кто мы",
              text: "Сила цветов: усиление красоты церемоний с помощью экспертного прикосновения Shine Flowers",
              info: "Цветочные композиции — это вид искусства, сочетающий красоту цветов с креативностью дизайна. Планируете ли вы свадьбу, проводите особое мероприятие или просто хотите добавить нотку элегантности в свой дом, хорошо продуманная цветочная композиция может произвести неизгладимое впечатление.",
            },
            discount: {
              priceText: "Off",
              text: "Лучшие предложения на этой неделе. Выбирайте и наслаждайтесь.",
            },
            ourProduct: {
              mainText: "НАШИ ПРОДУКТЫ",
              name: ["Цветы", "Аксессуары", "Украшения"],
            },
            popularOrders: {
              title: "ПОПУЛЯРНЫЕ ЗАКАЗЫ",
            },
            ourClientOptions: {
              title: "Что говорят наши клиенты",
              options: [
                {
                  text: "Внимание вашей команды к деталям превзошло все ожидания. Каждый лепесток, каждая свеча, каждая мельчайшая деталь были выполнены безупречно. Наша свадьба была похожа на мечту, и все это благодаря вашему опыту.",
                  author: "Сара Киракосян",
                },

                {
                  text: "Атмосфера, которую создают с помощью потрясающих цветочных композиций и декора, задала идеальный тон неожиданному дню. Вход в это место был похож на попадание в романтическую страну чудес. Мы всегда будем благодарны за ваше мастерство",
                  author: "Давид и Эмили Аветисян",
                },
                {
                  text: "С момента нашей первой встречи мы знали, что находимся в надежных руках. Ваш профессионализм, креативность и преданность своему делу сделали весь процесс планирования свадьбы безупречным. Наши гости до сих пор в восторге от потрясающих цветов и декора!",
                  author: "MМинистерство финансов и экономики Руанды",
                },
                {
                  text: "Невозможно выразить словами, насколько мы благодарны за невероятную работу, которую ваша команда вложила в нашу свадьбу. Каждая аранжировка была шедевром, а ваша способность передать наше видение превзошла все ожидания. Вы действительно сделали наш день незабываемым.",
                  author: "Приватная компания",
                },
                {
                  text: "Работать с вашей компанией было сплошное удовольствие. Страсть вашей команды к тому, что вы делаете, проявляется в каждой детали. Спасибо за то, что воплотили наши идеи в реальность и создали свадебный день, превзошедший наши самые смелые мечты.",
                  author: "Майкл и Лиза Джонсон",
                },
              ],
            },
            contactUs: {
              contactUsText: {
                title: "Связаться с нами",
                text: "В Shine Flowers & MORE мы уделяем приоритетное внимание открытому общению и стремимся предоставлять нашим клиентам отличный сервис. Если у вас есть вопросы, отзывы или возможности сотрудничества, мы здесь, чтобы выслушать и помочь вам на каждом этапе пути.",
                subtitleText:
                  "Наша команда стремится оперативно удовлетворить ваши потребности и гарантировать, что ваш опыт работы с нами будет не чем иным, как исключительным. Благодарим вас за выбор Shine Flowers & MORE.",
                subtitleInner:
                  "Не стесняйтесь обращаться к нам с любыми вопросами.",
                contactInfo: {
                  contactTitle: "НАЙДИТЕ НАШ МАГАЗИН",
                  address:
                    "Торговый центр «Ташир Стрит» Северный пр., 6/2, Ереван 0001",
                  open: "Сейчас Открыто",
                  close: "Закрыто",
                },
              },
              formText: {
                formTitle: "Давай поговорим",
                name: "Имя",
                email: "Электронная почта",
                phone: "Телефон",
                ourProduct: "Наш продукт",
                products: ["", "Цветы", "Свадьбы", "Украшения"],
                message: "Сообщение",
                button: "Отправить",
              },
            },
          },
          aboutUs: {
            header: "О нас",
            inner: "УЗНАЙТЕ НАС ЛУЧШЕ",
            text_1:
              "Добро пожаловать в Shine Flowers, где цветочное мастерство сочетается с непревзойденной элегантностью. Зайдите в наши потрясающие цветочные бутики и погрузитесь в атмосферу, где процветает красота и расцветает творчество. Наша команда опытных флористов под руководством нашего уважаемого креативного директора Лилит Топчян истинная страсть художников к каждому цветочному творению.",
            text_2:
              "В Shine Flowers мы черпаем вдохновение из последних тенденций в дизайне, моде и архитектуре, а также из присущей каждому цветку красоты. Наши цветочные инсталляции характеризуются чистыми линиями, свежими композициями и гармоничным балансом, гармонично сочетающимися друг с другом. привнося современную изысканность в любое пространство.",
            text_3:
              "Стремясь понять и превзойти ожидания наших клиентов, Shine Flowers накопила впечатляющую клиентуру. Будь то глубоко личное оформление, роскошные цветочные инсталляции для корпоративных мероприятий, частных мероприятий, показов мод, еженедельные цветочные контракты или изысканные концепции. для свадеб мы создаем наши творения на любой случай.",
            text_4:
              "Наша сфера деятельности распространяется на сферу моды, музыки, мероприятий и корпоративных сфер, поскольку мы используем свой творческий опыт для украшения корпоративных офисов, дизайнерских выставочных залов, роскошных бутиков, ресторанов, баров и отелей по всей Армении.",
            text_5:
              "В Shine Flowers мы не просто расставляем цветы; мы создаем впечатления, пробуждаем эмоции и преображаем пространство вечной красотой природы. Откройте для себя суть цветочной элегантности с Shine Flowers, где каждый лепесток рассказывает историю изысканного мастерство и изысканный артистизм.",
          },
          categories: "Категории",
          categoriesFilterFlowers: [
            "Все",
            "Популярный",
            "Состав",
            "Корзина",
            "Цветы",
            "Декорации",
          ],
          categoriesFilterAccessories: [
            "Все",
            "Популярный",
            "Свадьбы",
            "Крещение",
            "Валентина",
            "Рождество",
          ],
          categoriesFilterDecorations: [
            "Все",
            "Популярный",
            "Фотозоны",
            "Аренда",
          ],
          categoriesPlaceholder: " Поиск",
          navigateName: "Дом",
          auth: {
            signIn: {
              signInText: "Войти",
              emailText: "Электронная почта",
              passwordText: "Пароль",
              logIn: "Авторизоваться",
              forgotPassword: "Забыли пароль?",
              or: "или",
              noAccount: "Нет аккаунта?",
              signUpText: "Зарегистрироваться",
            },
            signUp: {
              text: "Пароль должен состоять из 8 или более символов и содержать как минимум 1 цифру и 1 специальный символ.",
              user: "Уже являетесь пользователем?",
            },
            reset: {
              resetTitle: "Сброс пароля",
              resetBtn: "Сбросить",
            },
            logOut: {
              logOutText: "Выйти",
              cancel: "Отмена",
              signOutText: "Выход",
              text: "Вы уверены, что хотите выйти из системы?",
            },
          },
          basket: {
            empty: "Ваша карта пуста.",
            yourCart: "Ваша корзина",
            basketItems: "Карзина",
            items: "Предметы",
            quantity: "Количество",
            subtotal: "Промежуточный итог",
            deliveryDate: "Дата доставки",
            remove: "Удалить",
            form: {
              basketContactNumber: "Контактный номер",
              contactNumber_placeholder: "Введите свой номер",
              basketEmail: "Электронная почта",
              email_placeholder: "Введите адрес электронной почты",
              basketAddress: "Адрес",
              address_placeholder: "Введите адрес доставки",
              basketMessage: "Сообщение",
              message_placeholder: "Введите ваше сообщение",
              cancelBtn: "Отмена",
              buyBtn: "Купить сейчас",
            },
          },
          payment: {
            paymentHeader: "Оплата",
            paymentMethod: "Способ оплаты",
            cashOrCard: ["Оплата при доставке", "Оплатить с", "Оплатить с"],
            myOrder: "Мой заказ",
            orderSummary: "Итог заказа",
            subtotal: "Промежуточный итог",
            delivery: "Доставка",
            total: "Общий",
            cancel: "Отмена",
            buy: "Купить",
            currency: "ДРАМ",
            free: "Бесплатно",
            policy:
              "Ваши персональные данные будут использоваться для обработки вашего заказа, поддержки вашего опыта на этом веб-сайте и для других целей, описанных в нашей Политике конфиденциальности.",
            orderMessage: "Спасибо за покупки",
            errorMessage: "Пожалуйста попробуйте снова",
          },
          favourites: {
            emptyTitle: "Собирайте и просматривайте свои любимые узоры.",
            emptyText:
              "Чтобы сохранить узор как любимый, коснитесь значка сердечка рядом с ним.",
            header: "Ваши избранные предметы",
            favLink: "Избранное",
          },
          btnGroup: {
            add: "Добавить",
            added: "Добавлено",
          },
          footer: {
            text: "Наполняя красоту каждым моментом, наши цветочные композиции олицетворяют элегантность и изящество. Украсьте свое пространство нашими творениями ручной работы, призванными очаровывать и вдохновлять, по одному цветку за раз.",
            contactUs: "Связаться с нами",
            address: "Ереван 0001, Северный проспект 2, 6",
            phone: "Телефон",
            email: "Электронная почта",
            newsLetter: "Новостная рассылка",
            newsLetterText:
              "Эксклюзивные акции: следите за эксклюзивными акциями и флэш-распродажами, доступными только для наших постоянных клиентов. Подпишитесь на нашу рассылку новостей и следите за нами в социальных сетях, чтобы быть в курсе последних предложений и скидок.",
          },
        },
      },
    },
  });
