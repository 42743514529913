import React from "react";
import GlassIcon from "../../assets/icons/GlassIcon";

const FilterForProduct = ({
  listItems,
  handleSubCategories,
  activeItem,
  categoriesList,
  handleSearchSubmit,
  handleChange,
  searchQuery,
  categoriesPlaceholder,
}) => {
  return (
    <div className="product_filer">
      <div className="product_name">
        <ul>
          {listItems.map((item, index) => (
            <li
              key={item.id}
              onClick={() => handleSubCategories(item.name)}
              className={item.name === activeItem ? "active" : ""}
            >
              {categoriesList[index]}
            </li>
          ))}
        </ul>
      </div>
      <div className="product_search">
        <form onSubmit={handleSearchSubmit}>
          <input
            type="text"
            placeholder={categoriesPlaceholder}
            value={searchQuery}
            onChange={(e) => handleChange(e)}
          />
          <button className="glass_icon">
            <GlassIcon />
          </button>
        </form>
      </div>
    </div>
  );
};

export default FilterForProduct;
