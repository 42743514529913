import React from 'react';
import layer from "../assets/pic/layer.png";

const NavigateFrame = ({title}) => {
  return (
    <>
      <div className="navigation_frame">
        <img src={layer} alt="layer"/>
        <span></span>
        <h4 className="layer_title">{title}</h4>
      </div>
    </>
  );
};

export default NavigateFrame;