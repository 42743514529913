import axios from "axios";
import axiosInstance from "./axiosInstance";

const getServerUrl = process.env.REACT_APP_AXIOS_LINK;

const axiosPostInstance = axios.create({
  baseURL: getServerUrl,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

axiosInstance.interceptors.request.use(
  (request) => {
    if (!request.headers["Content-Type"]) {
      request.headers["Content-Type"] = "multipart/form-data";
    }
    request.headers["Accept"] = "application/json";
    return request;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Do not forcefully set Content-Type in the response
    return response;
  },
  (err) => {
    console.log(err.message);
    if (err.response && err.response.status === 404) {
      console.log("not found");
    }
    return Promise.reject(err);
  },
);
export default axiosPostInstance;
