import React from "react";
import Header from "./Header";
import {Outlet} from "react-router-dom";
import Footer from "./Footer";
import ScrollToTop from "../components/ScrollToTop";

const Main = () => (
  <section className="landing_page">
    <Header/>
    <ScrollToTop/>
    <Outlet/>
    <Footer/>
  </section>
);


export default Main;
