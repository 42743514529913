import { productList } from "../../../utils/listItems";

export const getCheckedProduct = (category) => {
  switch (category) {
    case "Flowers":
      return productList.listItemsFlowers
        .filter((e) => e.name !== "All")
        .reduce((acc, item) => {
          acc[item.name] = item.isChecked || false;
          return acc;
        }, {});
    case "Accessories":
      return productList.listItemsAccessories
        .filter((e) => e.name !== "All")
        .reduce((acc, item) => {
          acc[item.name] = item.isChecked || false;
          return acc;
        }, {});
    case "Decorations":
      return productList.listItemsDecorations
        .filter((e) => e.name !== "All")
        .reduce((acc, item) => {
          acc[item.name] = item.isChecked || false;
          return acc;
        }, {});
    default:
      return {};
  }
};
