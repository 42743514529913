import React from 'react';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import mapImg from '../assets/pic/map_img.png'
import {Icon} from "leaflet/src/layer";
import {useTranslation} from "react-i18next";

const Map = () => {
  const {t} = useTranslation()
  const {address} = t('main.contactUs.contactUsText.contactInfo')
  const position = [40.18380827663519, 44.515164210648145];

  const customIcon = new Icon({
    iconUrl: require('../assets/pic/locationIcon.png'),
    iconSize: [38, 38],
  })
  return (
    <MapContainer center={position} zoom={20} style={{height: '480px'}}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={customIcon} riseOnHover={true}>
        <Popup>
          <div className='map_info'>
            <div className='map_img'>
              <img src={mapImg} alt="mapImg"/>
            </div>
            <div className='map_text'>
              <h5> SHINE FLOWERS & MORE</h5>
              <p>{address}</p>
              <span>+374 96 377 211</span>
            </div>
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
