import React, { useState } from "react";
import { auth, db } from "../../firebase/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import PassEyeIcon from "../../assets/icons/PassEyeIcon";
import { useDispatch } from "react-redux";
import { doc, setDoc } from "@firebase/firestore";
import Circular from "../Circular";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axios/axiosInstance";
import { setUser } from "../../features/authUser/authSlice";

function SignUp({ onClose, toggleAuthMode }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const { emailText, passwordText, signUpText, logIn } = t("auth.signIn");

  const addEmail = (e) => {
    setEmail(e.target.value);
    setIsEmpty(false);
  };
  const addPassword = (e) => {
    setPassword(e.target.value);
    setIsEmpty(false);
  };

  const { text, user } = t("auth.signUp");
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const signUp = async (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      setIsEmpty(true);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.email), {
        email,
        uid: user.uid,
      });
      await axiosInstance.post("/users", {
        authUserId: user?.uid,
        name: user?.displayName ? user.displayName : "",
        imageUrl: user?.photoURL ? user.photoURL : "",
        email: user.email,
      });
      dispatch(
        setUser({
          email: user.email,
          uid: user.uid,
          isAuth: true,
        }),
      );
      setIsEmpty(false);
      setIsAuthLoading(true);
      onClose();
    } catch (error) {
      console.error("Error signing up:", error.message);
      alert("Failed to sign up. Please try again.");
      setIsEmpty(false);
      setIsAuthLoading(false);
    } finally {
      setEmail("");
      setPassword("");
      setIsAuthLoading(false);
    }
  };

  return (
    <div className="signup_panel">
      <h2>{signUpText}</h2>
      {!isAuthLoading ? (
        <>
          <form name="signUp" className="form" onSubmit={signUp}>
            <div className="labels">
              <label className="input_box">
                <p className="input-box_title">{emailText}</p>
                <input
                  className={
                    isEmpty ? "text-input text-input_error" : "text-input"
                  }
                  type="email"
                  autoComplete="current-email"
                  value={email}
                  name="signUpEmail"
                  onChange={(e) => addEmail(e)}
                />
                {isEmpty && <p className="error-text">Please enter email.</p>}
              </label>
              <label className="input_box">
                <p className="input-box_title">{passwordText}</p>
                <div className="passEye">
                  <input
                    className={
                      isEmpty ? "text-input text-input_error" : "text-input"
                    }
                    name="signUpName"
                    autoComplete="current-pass"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => addPassword(e)}
                  />
                  <span className="eye" onClick={handleTogglePassword}>
                    <PassEyeIcon />
                  </span>
                </div>
                {isEmpty && (
                  <p className="error-text">Please enter password.</p>
                )}
              </label>
              <div className="pass_description">{text}</div>
            </div>
            <button className="btn_sign" type="submit">
              {signUpText}
            </button>
          </form>
          <div className="signIn_footer">
            <p>
              {" "}
              {user} <span onClick={toggleAuthMode}>{logIn}</span>
            </p>
          </div>
        </>
      ) : (
        <Circular />
      )}
    </div>
  );
}

export default SignUp;
