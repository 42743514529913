import React from 'react';
import EmptyCartIcon from "../assets/icons/EmptyCartIcon";

const EmptyCart = ({empty}) => (
  <div className='empty-cart'>
    <EmptyCartIcon/>
    <p>{empty}</p>
  </div>
);


export default EmptyCart;