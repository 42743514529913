// axios.defaults.headers.common["Accept"] = "application/json";
import axios from "axios";

const getServerUrl = process.env.REACT_APP_AXIOS_LINK;

const axiosInstance = axios.create({
  baseURL: getServerUrl,
});

axiosInstance.interceptors.request.use(
  (request) => {
    if (request.headers["Content-Type"] === null) {
      request.headers["Content-Type"] = "application/json";
    }
    request.headers["Accept"] = "application/json";
    request.headers["Content-Type"] = "application/json";
    return request;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    response.headers["Content-Type"] = "application/json";

    return response;
  },
  (err) => {
    console.log(err.message);
    if (err.message.response === 404) {
      console.log("not found");
    }
    return Promise.reject(err);
  },
);

export default axiosInstance;
