import React, { useEffect, useState } from "react";
import { Box, Button, LinearProgress, TextField } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../../axios/axiosInstance";
import {
  AllCategoriesBox,
  AllProdBox,
} from "../../../../assets/adminStyles/allProduct/allProduct";
import ModalDelete from "../ModalDelete";
import ImageIcon from "@mui/icons-material/Image";
import { checkedSubCategories } from "../../utils/checkedSubCategories/checkedSubCategories";

const AdminAllProducts = ({ lang }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState("");
  const [searchString, setSearchString] = useState("");
  const [deleteRow, setDeleteRow] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [total, setTotal] = useState(0);
  let formId = 1;

  const handleClose = () => setOpen(false);
  const columns = [
    { field: "formId", headerName: "ID", width: 10 },
    {
      field: "imageURls",
      headerName: "Image",
      width: 80,
      renderCell: (params) =>
        params.row.imageURLs.length ? (
          <img
            src={params.row.imageURLs[0]}
            alt="Pic"
            style={{
              width: 50,
              height: 50,
              objectFit: "cover",
              borderRadius: "10px",
            }}
            key={params.id}
          />
        ) : (
          <ImageIcon />
        ),
    },
    { field: "name", headerName: "Title", editable: true, width: 80 },
    // { field: "description", headerName: "Description" },
    { field: "category", headerName: "Categories" },
    { field: "price", headerName: "Price", width: 60 },
    { field: "discountPrice", headerName: "Discount", width: 60 },

    ...checkedSubCategories(categories),

    {
      field: "Edit",
      width: 80,
      headerName: "Edit",
      renderCell: (params) => {
        return [
          <Button
            variant={"contained"}
            key={`edit-${params.id}`}
            onClick={() => onEditRow(params.row)}
          >
            Edit
          </Button>,
        ];
      },
    },
    {
      field: "Delete",
      width: 100,
      headerName: "Delete",

      renderCell: (params) => {
        return [
          <Button
            key={`delete-${params.id}`}
            variant={"contained"}
            color="error"
            onClick={() => onDelete(params.row.uuid)}
          >
            Delete
          </Button>,
        ];
      },
    },
  ];

  const getAllProducts = async () => {
    setIsLoading(true);
    try {
      const allProds = await axiosInstance.get(`/products`, {
        params: {
          searchString,
          category: categories ? categories : null,
          page: page,
        },
        headers: {
          "Accept-Language": lang,
        },
      });
      setProducts(allProds.data?.items.products);
      setPageSize(allProds.data?.pageSize);
      setTotal(allProds.data?.totalItems);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("Error fetching products:", err);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [categories, searchString, page]);

  const handleSelect = (e) => {
    const value = e.target.value;
    value !== "All" ? setCategories(value) : setCategories("");
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchString(value);
  };

  const onEditRow = (item) => {
    navigate(`/edit-product/${item.uuid}`);
  };

  const onDelete = (id) => {
    setOpen(true);
    setDeleteRow(id);
  };

  const goToPage = (p) => {
    if (p > 1) {
      const prevPage = p - 1;
      setPage(prevPage);
    } else {
      const nextPage = p + 1;
      setPage(nextPage);
    }
  };

  return (
    <>
      <AllProdBox>
        <AllCategoriesBox>
          <FormControl sx={{ maxWidth: 300 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={categories ? categories : "All"}
              required
              label="Categories"
              onChange={handleSelect}
            >
              <MenuItem value={"All"}>All Categories</MenuItem>
              <MenuItem value={"Flowers"}>Flowers</MenuItem>
              <MenuItem value={"Accessories"}>Accessories</MenuItem>
              <MenuItem value={"Decorations"}>Decorations</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            label="Search item"
            value={searchString}
            name="searchItem"
            onChange={handleSearch}
            InputProps={{
              endAdornment: <SearchOutlinedIcon position="end" />,
            }}
          />
        </AllCategoriesBox>
        <Box>
          <DataGrid
            columns={columns.map((column) => ({
              ...column,
              sortable: true,
              editable: false,
            }))}
            rows={products?.map((product) => ({
              key: product.uuid,
              ...product,
              formId: formId++,
            }))}
            getRowId={(row) => row.uuid}
            rowPerPage={[15, 20, 25]}
            initialState={{
              ...products.initialState,
              pagination: {
                paginationModel: {
                  pageSize,
                  page,
                },
              },
            }}
            rowCount={total}
            paginationMode="server"
            onPaginationModelChange={(p) => {
              goToPage(p.page);
            }}
            autoPageSize={true}
            slots={{
              loadingOverlay: LinearProgress,
            }}
            loading={isLoading}
            sx={{
              pb: 20,
              "& .MuiDataGrid-virtualScrollerContent": {
                paddingTop: "10px",
              },
              "& .MuiDataGrid-virtualScroller": { overflow: "hidden" },
              "& .MuiDataGrid-main ": { height: "600px" },
            }}
          />
        </Box>
      </AllProdBox>

      {open && (
        <ModalDelete
          open={open}
          handleClose={handleClose}
          id={deleteRow}
          setProducts={setProducts}
          products={products}
        />
      )}
    </>
  );
};

export default AdminAllProducts;
