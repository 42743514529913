import { v4 as uuidv4 } from "uuid";
import categoriesPic1 from "../assets/pic/ourprimg.jpg";
import OurProductsGoBtn from "../assets/icons/OurProductsGoBtn";
import React from "react";
import categoriesPic2 from "../assets/pic/ourprimg2.jpg";
import categoriesPic3 from "../assets/pic/ourprimg3.jpg";

export const categories = [
  {
    id: uuidv4(),
    link: "/categories/flowers",
    img: categoriesPic1,
    title: "Categories-pic1",
    // name: 'Flowers',
    icon: <OurProductsGoBtn />,
  },
  {
    id: uuidv4(),
    link: "/categories/accessories",
    img: categoriesPic2,
    title: "Categories-pic2/",
    // name: 'Decorations',
    icon: <OurProductsGoBtn />,
  },
  {
    id: uuidv4(),
    link: "/categories/decorations",
    img: categoriesPic3,
    title: "Categories-pic3",
    // name: 'Accessories',
    icon: <OurProductsGoBtn />,
  },
];
