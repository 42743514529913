import React from "react";

const InstagramIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
    <path
      d="M11.9987 10.3326C11.0806 10.3326 10.3313 11.0819 10.3313 12C10.3313 12.9181 11.0806 13.6674 11.9987 13.6674C12.9168 13.6674 13.666 12.9181 13.666 12C13.666 11.0819 12.9168 10.3326 11.9987 10.3326ZM16.9994 12C16.9994 11.3095 17.0057 10.6253 16.9669 9.93611C16.9281 9.13556 16.7455 8.42508 16.1601 7.83969C15.5735 7.25304 14.8643 7.07167 14.0638 7.03289C13.3733 6.99412 12.6891 7.00037 11.9999 7.00037C11.3095 7.00037 10.6253 6.99412 9.93605 7.03289C9.13552 7.07167 8.42506 7.25429 7.83967 7.83969C7.25304 8.42634 7.07167 9.13556 7.03289 9.93611C6.99412 10.6266 7.00037 11.3108 7.00037 12C7.00037 12.6892 6.99412 13.3747 7.03289 14.0639C7.07167 14.8644 7.25429 15.5749 7.83967 16.1603C8.42631 16.747 9.13552 16.9283 9.93605 16.9671C10.6265 17.0059 11.3107 16.9996 11.9999 16.9996C12.6904 16.9996 13.3746 17.0059 14.0638 16.9671C14.8643 16.9283 15.5748 16.7457 16.1601 16.1603C16.7468 15.5737 16.9281 14.8644 16.9669 14.0639C17.0069 13.3747 16.9994 12.6905 16.9994 12ZM11.9987 14.5655C10.579 14.5655 9.43322 13.4197 9.43322 12C9.43322 10.5803 10.579 9.43452 11.9987 9.43452C13.4183 9.43452 14.5641 10.5803 14.5641 12C14.5641 13.4197 13.4183 14.5655 11.9987 14.5655ZM14.6692 9.9286C14.3377 9.9286 14.07 9.66092 14.07 9.32945C14.07 8.99797 14.3377 8.73029 14.6692 8.73029C15.0006 8.73029 15.2683 8.99797 15.2683 9.32945C15.2684 9.40816 15.253 9.48611 15.2229 9.55885C15.1928 9.63159 15.1487 9.69768 15.093 9.75333C15.0374 9.80899 14.9713 9.85312 14.8986 9.88319C14.8258 9.91327 14.7479 9.9287 14.6692 9.9286Z"
      fill="#545454"
    />
  </svg>
);

export default InstagramIcon;
