import React from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {useNavigate} from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/')
  }
  return (
    <div className='not-found'>
      <ReportProblemIcon
        sx={{
          fill: '#F56E91',
          fontSize: '80px'
        }}
      />
      <h3 className='not-fount_text'>Error 404: Something isn’t working or missing.</h3>
      <button onClick={handleNavigate}>Go to home</button>
    </div>
  );
};

export default NotFound;