import React from "react";
import { socialLinks } from "../utils/socialLinks";
import logo from "../assets/pic/logo.png";
import SendButton from "../assets/icons/SendButton";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { text, contactUs, address, phone, email, newsLetter, newsLetterText } =
    t("footer");
  return (
    <footer className="footer">
      <div className="footer_title">
        <div className="social">
          <div className="social_logo">
            <NavLink to="/" className="social_link">
              <img src={logo} alt="logo" />
              Shine <br />
              Flowers <br />& Events
            </NavLink>
          </div>
          <div className="social_text">
            <p>{text}</p>
          </div>
          <div className="social_icons">
            {socialLinks.map((item) => (
              <a
                href={item.link}
                key={item.id}
                rel="noreferrer"
                target="_blank"
              >
                {item.icon}
              </a>
            ))}
          </div>
        </div>
        <div className="footer-contact">
          <h4 className="contact_title">{contactUs}</h4>
          <ul>
            <li>
              <a href="https://www.google.com/maps?q40.18374270381241,44.51528222783788">
                {address}
              </a>
            </li>
            <li>
              {phone} <a href="tel:+37455377211">+37455377211 </a>
              <a href="tel:+37496377211">+37496377211</a>
            </li>
            <li>
              <a href="mailto:info@shineflowers.am">{`${email} E-mail: info@shineflowers.am`}</a>
            </li>
          </ul>
        </div>
        <div className="newsletter">
          <h4 className="newsletter_title">{newsLetter}</h4>
          <p className="newsletter_text">{newsLetterText}</p>
          <div className="newsletter_input">
            <input type="text" />
            <button>
              <SendButton />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
