import React from "react";

const ArrowBottom = () => (
  <svg
    width="20"
    height="13"
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.34375 0.296869L10 7.95312L17.6562 0.296872L20 2.64062L9.99999 12.6406L-3.63692e-06 2.64062L2.34375 0.296869Z"
      fill="#161616"
    />
  </svg>
);
export default ArrowBottom;
