import React, { useRef, useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ResetPassword from "./ResetPassword";
import { selectUserAuth } from "../../features/authUser/authSlice";
import { useSelector } from "react-redux";
import SignOut from "./SignOut";
import { UserAuth } from "../../assets/adminStyles/payment/modalCustomStyles";

const AuthModal = ({ onClose, open }) => {
  const modalRef = useRef();
  const auth = useSelector(selectUserAuth);
  const [reset, setReset] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);

  const toggleAuthMode = () => {
    setIsSignIn((prev) => !prev);
  };

  const toggleReset = () => {
    setReset((prev) => !prev);
  };

  return (
    <UserAuth open={open} onClose={onClose}>
      {/*<UserAuth>*/}
      <div className="auth-modal-content" ref={modalRef}>
        {!auth ? (
          !reset ? (
            isSignIn ? (
              <SignIn
                toggleAuthMode={toggleAuthMode}
                onClose={onClose}
                toggleReset={toggleReset}
              />
            ) : (
              <SignUp toggleAuthMode={toggleAuthMode} onClose={onClose} />
            )
          ) : (
            <ResetPassword onClose={onClose} toggleReset={toggleReset} />
          )
        ) : (
          <SignOut onClose={onClose} />
        )}
      </div>
    </UserAuth>
  );
};

export default AuthModal;
