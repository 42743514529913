import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { Alert, Snackbar } from "@mui/material";

const MainContact = () => {
  const form = useRef();
  const { t } = useTranslation();
  // const products = ["", "Flowers", "Decorations", "Decorations"];
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorSent, setErrorSent] = useState(false);
  const { title, text, subtitleText, subtitleInner } = t(
    "main.contactUs.contactUsText",
  );
  const {
    formTitle,
    name,
    email,
    phone,
    products,
    message,
    ourProduct,
    button,
  } = t("main.contactUs.formText");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessAlert(false);
    setErrorSent(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLICK_KEY,
      )
      .then(
        (result) => {
          setSuccessAlert(true);
          setErrorSent(false);
        },
        (error) => {
          setErrorSent(true);
          setSuccessAlert(false);
        },
      );
    e.target.reset();
  };

  return (
    <div className="contact">
      <div className="contact_container">
        <h2 className="contact_title">{title}</h2>
        <div className="contact_text">
          <p className="text">{text}</p>
          <p className="text">{subtitleText}</p>
          <p className="text">{subtitleInner}</p>
        </div>
      </div>

      <div className="form_container">
        <h2 className="form_title">{formTitle}</h2>
        <form ref={form} className="form_message" onSubmit={sendEmail}>
          <div className="labels">
            <label className="labels-inner">
              <p>{name} *</p>
              <input type="text" name="name" autoComplete="current-name" />
            </label>

            <label className="labels-inner">
              <p>{email} *</p>
              <input type="email" name="email" autoComplete="current-email" />
            </label>
          </div>
          <div className="labels">
            <label className="labels-inner">
              <p>{phone}</p>
              <input name="phone" autoComplete="current-number" type="tel" />
            </label>
            <label className="labels-inner">
              <p>{ourProduct}</p>
              <select name="products">
                {products.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="labels">
            <label className="labels-inner textarea">
              <p>{message}</p>
              <textarea name="message" />
            </label>
          </div>
          <div className="form-button">
            <button className="btn_message" type="submit">
              {button}
            </button>
          </div>
        </form>
      </div>
      <Snackbar
        open={successAlert}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          // severity="success"
          variant="filled"
          sx={{ width: "100%", backgroundColor: "#B0D404" }}
        >
          Success! Your email has been sent.
        </Alert>
      </Snackbar>
      <Snackbar open={errorSent} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Problem with send email. Please try again
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MainContact;
