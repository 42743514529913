import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  email: '',
  uid: '',
  displayName: '',
  photoURL: '',
  isAuth: false,
}
export const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.email = action.payload.email
      state.uid = action.payload.uid
      state.displayName = action.payload.displayName
      state.photoURL = action.payload.photoURL
      state.isAuth = true;
    },
    removeUser(state) {
      state.email = ''
      state.uid = ''
      state.displayName = ''
      state.photoURL = ''
      state.isAuth = false
    },
  }
})

export const {setUser, removeUser} = authSlice.actions

export default authSlice.reducer

export const selectUser = (s) => s.user;
export const selectEmail = (e) => e.user.email;
export const selectUserAuth = (s) => s.user.isAuth;
export const selectUserId = (s) => s.user.uid;
export const selectUserName = (s) => s.user.displayName;