// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

a {
    text-decoration: none;
    color: inherit;

}

.landing_page {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, .4);
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,cAAc;;AAElB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,6BAA6B;AACjC","sourcesContent":["body {\n    font-family: 'Roboto', sans-serif;\n    font-weight: 400;\n    font-size: 14px;\n}\n\na {\n    text-decoration: none;\n    color: inherit;\n\n}\n\n.landing_page {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    height: 100vh;\n}\n\n.circle {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    background: rgba(0, 0, 0, .4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
