import React from "react";
import { Route, Routes } from "react-router-dom";
import Admin from "../admin/Admin";
import AdminDashboard from "../admin/admin-components/AdminDashboard";
import AdminSlides from "../admin/admin-components/AdminSlides";
import EditProduct from "../admin/admin-components/EditProduct";
import AdminAddProducts from "../admin/admin-components/addProduct/AdminAddProducts";
import AdminAllLangProds from "../admin/admin-components/allPrograms/AdminAllLangProds";
import AdminUsers from "../admin/admin-components/users/AdminUsers";

const Private = () => {
  return (
    <Routes>
      <Route path="/" element={<Admin />}>
        <Route index element={<AdminAllLangProds />} />
        <Route path="/users" element={<AdminUsers />} />
        <Route path="/slider" element={<AdminSlides />} />
        <Route path="/add-products" element={<AdminAddProducts />} />
        <Route path="/dashboard" element={<AdminDashboard />} />
        <Route path="/edit-product/:id" element={<EditProduct />} />
      </Route>
    </Routes>
  );
};

export default Private;
