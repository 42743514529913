import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productItem: {},
  productForBuying: [],
  status: "idle",
  addedProduct: [],
  totalPrice: 0,
};

// export const productAsync =
//   createAsyncThunk();
//   // "product/fetchProduct",
//   // async (item) => {
//   //   const response = await fetchProduct(item);
//   //   return response.data;
//   // },

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setAddedProduct: (state, action) => {
      let { uuid } = action.payload;
      const isDuplicate = state.addedProduct.some((item) => item.uuid === uuid);
      if (!isDuplicate) {
        state.addedProduct.push(action.payload);
      } else {
        state.addedProduct = state.addedProduct.filter(
          (item) => item.uuid !== uuid,
        );
      }
    },
    getProductForBuying: (state, action) => {
      state.productForBuying = action.payload;
    },
    removeProductForBuying: (state, action) => {
      state.productForBuying = [];
    },
    setProductItem: (state, action) => {
      state.productItem = action.payload;
    },
    cancelCart: (state) => {
      state.addedProduct = [];
      state.productForBuying = [];
    },
    getTotalPrice: (state, action) => {
      state.totalPrice = action.payload;
    },

    setProductDescription: (state, action) => {
      const { itemPrice } = action.payload;
      state.productItem = {
        ...action.payload,
        price: itemPrice,
      };
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(productAsync.pending, (state) => {
  //       state.status = "loading";
  //     })
  //     .addCase(productAsync.fulfilled, (state, action) => {
  //       state.status = "idle";
  //       state.productItem = action.payload;
  //     });
  // },
});

export const {
  setAddedProduct,
  setProductDescription,
  cancelCart,
  getTotalPrice,
  getProductForBuying,
  setProductItem,
  removeProductForBuying,
} = productSlice.actions;

export const selectAddedProduct = (state) => state.product?.addedProduct;
export const selectProductForBuying = (state) =>
  state.product?.productForBuying;
export default productSlice.reducer;
