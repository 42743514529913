import React from "react";
import Slider from "react-slick";


import {ourPartnersImg} from "../utils/mockImg";
import {ourPartnerSettings} from "../utils/sliderSettings";

const OurPartnersBlock = () => (
  <div className="special-order">
    <h2 className="title-header">Our Partners</h2>
    <div className="special-order-block-bg">
      <Slider {...ourPartnerSettings}>
        {ourPartnersImg.map((item) => (
          <div key={item.id} className="our-partners-bg">
            <img src={item.src} alt={item.alt}/>
          </div>
        ))}
      </Slider>
    </div>
  </div>
);

export default OurPartnersBlock;
