import React from "react";
import {useTranslation} from "react-i18next";
import {categories} from "../utils/categories";
import {useNavigate} from "react-router-dom";

const OurProducts = () => {
  const {t} = useTranslation()
  const {name} = t('main.ourProduct')
  const navigate = useNavigate()
  const handleNavigate = (link) => {
    navigate(`${link}`)
  }


  return (
    <div className="our-products-block">
      <div className="header-text">
        <span className="title-header">{t('main.ourProduct.mainText')}</span>
      </div>
      <div className="our-products-img-block">
        <div className="our-products-title">
          {categories.map((item, index) => (
            <div
              key={item.id}
              className="our-products-img-item"
              onClick={() => handleNavigate(item.link)}
            >
              <img src={item.img} alt={item.title} className="our-products-img"/>
              <p className="our-products-text">{name[index]} {item.icon}</p>
            </div>
          ))}

        </div>
      </div>

    </div>
  );
}

export default OurProducts;

