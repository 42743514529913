import React from "react";

const OurProductsGoBtn = () => (
<svg 
    width="34" 
    height="34" 
    viewBox="0 0 34 34" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
>
<path 
    d="M17 0.300781L33.6992 17L17 33.6992L14.0703 30.7695L25.6914 19.0508H0.300781V14.9492H25.6914L14.0703 3.23047L17 0.300781Z" 
    fill="white"
/>
</svg>

);

export default OurProductsGoBtn;
