import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cancelCart,
  getProductForBuying,
} from "../features/products/productSlice";
import NavigateSection from "../components/NavigateSection";
import { paymentMethods } from "../utils/paymentMethods";
import { useTranslation } from "react-i18next";
import Circular from "../components/Circular";
import emailjs from "@emailjs/browser";
import {
  selectEmail,
  selectUserId,
  selectUserName,
} from "../features/authUser/authSlice";
import axiosInstance from "../axios/axiosInstance";
import { Alert, Box, Snackbar } from "@mui/material";

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector(selectUserName);
  const userEmail = useSelector(selectEmail);
  const userId = useSelector(selectUserId);
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("Cash On Delivery");
  const [orderSend, setOrderSend] = useState(false);
  const [errorSend, setErrorSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const deliveryCount = 20000;
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const { orderMessage } = t("payment");
  const { errorMessage } = t("payment");

  const {
    paymentHeader,
    paymentMethod,
    cashOrCard,
    myOrder,
    orderSummary,
    subtotal,
    delivery,
    total,
    cancel,
    buy,
    currency,
    free,
    policy,
  } = t("payment");

  const getBuyingItems = async () => {
    try {
      setIsLoading(true);
      const orderItemsResponse = await axiosInstance.get(
        `/users/${userId}/order`,
      );
      const orderItemsData = orderItemsResponse?.data.data;
      setProducts(orderItemsData);
      dispatch(getProductForBuying(orderItemsData));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBuyingItems();
  }, [userId, dispatch]);

  useEffect(() => {
    let price = 0;
    products.forEach((item) => {
      price += item.orderProducts[0].subtotal;
    });
    setSubTotalPrice(price);

    if (price < deliveryCount) {
      setDeliveryPrice(2000);
      setFreeDelivery(false);
      setTotalPrice(price + 2000);
    } else {
      setDeliveryPrice(0);
      setFreeDelivery(true);
      setTotalPrice(price);
    }
  }, [products]);

  const handlePaymentChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleOrder = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const buyingProduct = {
      orders: products.map((item) => ({
        order_name: item.orderProducts[0].product.name,
        order_itemPrice: item.orderProducts[0].product.discountPrice,
        order_price: item.orderProducts[0].subtotal,
        order_quantity: item.orderProducts[0].count,
        categories: item.orderProducts[0].product.category,
        deliverDate: item.deliveryDate.split("T")[0],
      })),
      contactNumber: products[0].contactNumber,
      address: products[0].deliveryAddress,
      message: products[0].message,
      payment_method: selectedPaymentMethod,
      total_price: totalPrice,
      delivery: deliveryPrice,
      from_name: userName,
      userEmail: products[0].email,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID_ORDER,
        buyingProduct,
        process.env.REACT_APP_PUBLICK_KEY,
      );

      await Promise.all(
        products.map((item) =>
          axiosInstance.delete(`/users/${userId}/order/${item.id}`),
        ),
      );

      dispatch(cancelCart());
      setOrderSend(true);
      setIsLoading(false);

      setTimeout(() => {
        navigate("/");
      }, 5000);
    } catch (error) {
      setErrorSend(true);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOrderSend(false);
    setErrorSend(false);
  };

  return (
    <section className="section_padding">
      <div className="payment">
        <NavigateSection link={paymentHeader} header={paymentMethod} />
        <div className="payment-method">
          <form className="payment-method_form">
            {paymentMethods?.map((item, index) => (
              <label key={item.id} className="payment-method_label">
                <input
                  type="radio"
                  value={item.title}
                  name={cashOrCard[index]}
                  className="paymentMethod"
                  checked={selectedPaymentMethod === item.title}
                  onChange={handlePaymentChange}
                />
                <p className="payment-name">{cashOrCard[index]}</p>
                {item.image && <img src={item.image} alt={item.title} />}
              </label>
            ))}
          </form>
        </div>

        <div className="payment-order">
          <div className="my-order">
            <h3>{myOrder}</h3>
            <div className="my-order_lists-group">
              {products?.map((item) => (
                <div className="my-order_list" key={item.id}>
                  <img
                    src={item.orderProducts[0].product.imageURLs[0]}
                    alt=""
                    className="order-img"
                  />
                  <div className="order-inner">
                    <p className="order_title-name">
                      {item.orderProducts[0].product.name.length < 19
                        ? item.orderProducts[0].product.name
                        : item.orderProducts[0].product.name.slice(0, 16) +
                          " ..."}
                    </p>
                    <div className="order-price">
                      <p className="order_price-discount">
                        {item.orderProducts[0].count} count -{" "}
                      </p>
                      <p className="order_price-count">
                        {item.orderProducts[0].subtotal} {currency}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="order-divider"></div>

          <div className="order-summary">
            <h3>{orderSummary}</h3>
            <div className="order-summary_inner">
              <div className="summary-title">
                <div className="summary_inner">
                  <p className="subtotal-text">{subtotal}</p>
                  <p className="subtotal-price">
                    {`${subTotalPrice} ${currency}`}
                  </p>
                </div>

                <div className="summary_inner">
                  <p className="subtotal-text">{delivery}</p>
                  <p className="subtotal-price">
                    {freeDelivery ? `${free}` : `${deliveryPrice} ${currency}`}
                  </p>
                </div>
                <span className="summary-divider"></span>
                <div className="summary_inner">
                  <p className="subtotal-text">{total}</p>
                  <p className="summary_total-price">{`${totalPrice} ${currency}`}</p>
                </div>
              </div>

              <div className="summary-button">
                <button className="cancel-btn" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className="buy-btn" onClick={handleOrder}>
                  {buy}
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="policy-text">
          {policy}
          <a href="#!">privacy policy</a>
        </p>
      </div>
      {isLoading && <Circular />}

      {orderSend && (
        <Box
          spacing={2}
          sx={{ maxWidth: 600, display: "flex", alignItems: "center" }}
        >
          <div className="circle">
            <p className="order-message">{orderMessage}</p>
          </div>
        </Box>
      )}
      <Snackbar open={errorSend} autoHideDuration={8000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}{" "}
        </Alert>
      </Snackbar>
    </section>
  );
};

export default Payment;
