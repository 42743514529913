import React from 'react';

const FacebookFooterIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#D9D9D9"/>
    <path
      d="M13.6667 13.475H15.3333L16 10.875H13.6667V9.575C13.6667 8.9055 13.6667 8.275 15 8.275H16V6.091C15.7827 6.06305 14.962 6 14.0953 6C12.2853 6 11 7.07705 11 9.055V10.875H9V13.475H11V19H13.6667V13.475Z"
      fill="#545454"/>
  </svg>

)

export default FacebookFooterIcon;