import React from "react";


const PassEyeIcon = () => (
  <svg width="20" 
  height="14" 
  viewBox="0 0 20 14" 
  fill="none" 
  xmlns="http://www.w3.org/2000/svg">
  <path d="M8.24219 5.24219C8.73698 4.7474 9.32292 4.5 10 4.5C10.6771 4.5 11.263 4.7474 11.7578 5.24219C12.2526 5.73698 12.5 6.32292 12.5 7C12.5 7.67708 12.2526 8.26302 11.7578 8.75781C11.263 9.2526 10.6771 9.5 10 9.5C9.32292 9.5 8.73698 9.2526 8.24219 8.75781C7.7474 8.26302 7.5 7.67708 7.5 7C7.5 6.32292 7.7474 5.73698 8.24219 5.24219ZM7.03125 9.96875C7.86458 10.776 8.85417 11.1797 10 11.1797C11.1458 11.1797 12.1224 10.776 12.9297 9.96875C13.763 9.13542 14.1797 8.14583 14.1797 7C14.1797 5.85417 13.763 4.8776 12.9297 4.07031C12.1224 3.23698 11.1458 2.82031 10 2.82031C8.85417 2.82031 7.86458 3.23698 7.03125 4.07031C6.22396 4.8776 5.82031 5.85417 5.82031 7C5.82031 8.14583 6.22396 9.13542 7.03125 9.96875ZM4.41406 2.46875C6.08073 1.32292 7.94271 0.75 10 0.75C12.0573 0.75 13.9193 1.32292 15.5859 2.46875C17.2526 3.61458 18.4505 5.125 19.1797 7C18.4505 8.875 17.2526 10.3854 15.5859 11.5312C13.9193 12.6771 12.0573 13.25 10 13.25C7.94271 13.25 6.08073 12.6771 4.41406 11.5312C2.7474 10.3854 1.54948 8.875 0.820312 7C1.54948 5.125 2.7474 3.61458 4.41406 2.46875Z" fill="#ADADAD"/>
  </svg>
);

export default PassEyeIcon;
