import React from "react";

import DeliveryIcon from "../assets/icons/DeliveryIcon";
import { imagesList } from "../utils/mockImg";
import { useTranslation } from "react-i18next";

const HomeSlider = () => {
  const { t } = useTranslation();
  const { title, subtitle } = t("main.header_text");

  return (
    <div className="slider">
      {imagesList.map((item, index) => (
        <div key={item.id} className="slide-bg">
          <div className="img-block">
            <img src={item.src} alt={item.alt} />
            <div className="slider-text-block">
              <h2 className="slider-title">
                {item.title}
                <br />
                <span> {item.subtitle}</span>
              </h2>
              <div className="slider-text">
                <p className="slider-description">
                  {" "}
                  {t(`main.header_text.text_${index + 1}`)}
                </p>
              </div>
              <div className="deliver">
                <h4 className="delivery-title">
                  {title}
                  <br />
                  <span>{subtitle} 20000+</span>
                </h4>
                <DeliveryIcon />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeSlider;
