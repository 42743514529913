import { Checkbox } from "@mui/material";
import React from "react";

export const getCheckedAccessories = () => {
  return [
    {
      field: "Popular",
      headerName: "Popular",
      width: 20,
      renderCell: (params) => {
        const isChecked = params.row.subCategories.includes("Popular");
        return [
          <Checkbox
            variant={"contained"}
            key={`edit-${params.uuid}`}
            checked={isChecked}
          />,
        ];
      },
      editable: false,
    },
    {
      field: "Weddings",
      headerName: "Weddings",
      width: 20,
      renderCell: (params) => {
        const isChecked = params.row.subCategories.includes("Weddings");
        return [
          <Checkbox
            variant={"contained"}
            key={`edit-${params.uuid}`}
            checked={isChecked}
          />,
        ];
      },
      editable: false,
    },
    {
      field: "Baptizing",
      headerName: "Baptizing",
      width: 20,
      renderCell: (params) => {
        const isChecked = params.row.subCategories.includes("Baptizing");
        return [
          <Checkbox
            variant={"contained"}
            key={`edit-${params.uuid}`}
            checked={isChecked}
          />,
        ];
      },
      editable: false,
    },
    {
      field: "Valentine",
      headerName: "Valentine",
      width: 20,
      renderCell: (params) => {
        const isChecked = params.row.subCategories.includes("Valentine");
        return [
          <Checkbox
            variant={"contained"}
            key={`edit-${params.uuid}`}
            checked={isChecked}
          />,
        ];
      },
      editable: false,
    },
    {
      field: "Christmas",
      headerName: "Christmas",
      width: 20,
      renderCell: (params) => {
        const isChecked = params.row.subCategories.includes("Christmas");
        return [
          <Checkbox
            variant={"contained"}
            key={`edit-${params.uuid}`}
            checked={isChecked}
          />,
        ];
      },
      editable: false,
    },
  ];
};
