import { v4 as uuidv4 } from "uuid";
import InstagramIcon from "../assets/icons/InstagramIcon";
import FacebookFooterIcon from "../assets/icons/FacebookFooterIcon";

export const srcLink = process.env.REACT_APP_AXIOS_LINK;

export const socialLinks = [
  {
    id: uuidv4(),
    icon: <FacebookFooterIcon />,
    link: "https://www.facebook.com/shineflowersevent",
  },
  {
    id: uuidv4(),
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/shine_flowers_and_more/",
  },
];
