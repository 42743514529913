
import React from 'react'

const GoogleIcon = () => (
  <svg width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="34.684" height="35.0005" rx="17.342" stroke="#C50000" />
    <path d="M25.545 16.5585C25.6383 17.0955 25.6848 17.6395 25.684 18.1845C25.684 20.6185 24.814 22.6765 23.3 24.0695H23.302C21.978 25.2925 20.158 26.0005 18 26.0005C15.8783 26.0005 13.8434 25.1577 12.3431 23.6574C10.8429 22.1571 10 20.1223 10 18.0005C10 15.8788 10.8429 13.844 12.3431 12.3437C13.8434 10.8434 15.8783 10.0005 18 10.0005C19.9859 9.97727 21.9038 10.7234 23.352 12.0825L21.068 14.3665C20.2424 13.5795 19.1405 13.1485 18 13.1665C15.913 13.1665 14.14 14.5745 13.508 16.4705C13.1729 17.464 13.1729 18.54 13.508 19.5335H13.511C14.146 21.4265 15.916 22.8345 18.003 22.8345C19.081 22.8345 20.007 22.5585 20.725 22.0705H20.722C21.1389 21.7943 21.4955 21.4365 21.7704 21.0187C22.0452 20.6009 22.2325 20.1317 22.321 19.6395H18V16.5595L25.545 16.5585Z" 
    fill="#C50000" />
  </svg>

)

export default GoogleIcon;