import React, { useState } from "react";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  ProductBox,
  ProductTabs,
} from "../../../../assets/adminStyles/addProduct/addProducts";
import {
  AdminHeader,
  Section,
} from "../../../../assets/adminStyles/user/users";
import LanguageTab, { a11yProps } from "../../languageTab/LanguageTab";
import AdminAddProduct from "./AdminAddProduct";

const AdminAddProducts = () => {
  const [value, setValue] = useState(0);
  const label = {
    hy: {
      name: "Ապրանքի անվանումը",
      description: "Ապրանքի մասին",
    },
    ru: {
      name: "Название продукта",
      description: "О продукте",
    },
    en: {
      name: "Product name",
      description: "About product",
    },
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Section>
      <AdminHeader>Add Product</AdminHeader>
      <ProductBox>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="ARM" value={0} {...a11yProps(0)} />
            <Tab label="RUS" value={1} {...a11yProps(1)} />
            <Tab label="ENG" value={2} {...a11yProps(2)} />
          </Tabs>
        </Box>
        <ProductTabs>
          <LanguageTab value={value} index={0}>
            <AdminAddProduct lang="hy" label={label.hy} />
          </LanguageTab>
          <LanguageTab value={value} index={1}>
            <AdminAddProduct lang="ru" label={label.ru} />
          </LanguageTab>
          <LanguageTab value={value} index={2}>
            <AdminAddProduct lang="en" label={label.en} />
          </LanguageTab>
        </ProductTabs>
      </ProductBox>
    </Section>
  );
};

export default AdminAddProducts;
