import React from "react";
import WhoWeAre from "../components/WhoWeAre";
import HomeSlider from "../components/HomeSlider";
import OurProducts from "../components/OurProducts";
import SpecialOrder from "../components/SpecialOrder";
import OurPartners from "../components/OurPartners";
import OurClientOpinion from "../components/OurClientOpinion";
import MainContact from "./MainContact";

const Home = () => (
  <>
    <HomeSlider />
    <WhoWeAre />
    {/*<Discount/>*/}
    <OurProducts />
    <SpecialOrder />
    <OurPartners />
    <OurClientOpinion />
    <MainContact />
  </>
);

export default Home;
