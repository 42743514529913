import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from '../features/counter/counterSlice';
import productReducer from "../features/products/productSlice";
import favoriteReducer from "../features/favorites/favoritesSlice";
import allProductsReducer from "../features/allProducts/allProducts";

import authUserReducer from "../features/authUser/authSlice";

export const store = configureStore({
  reducer: {
    product: productReducer,
    favorite: favoriteReducer,
    allProducts: allProductsReducer,
    user: authUserReducer,
  },
});
