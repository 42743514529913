import { v4 as uuidv4 } from "uuid";

export const productList = {
  listItemsFlowers: [
    { id: uuidv4(), name: "All", isChecked: false },
    { id: uuidv4(), name: "Popular", isChecked: false },
    { id: uuidv4(), name: "Composition", isChecked: false },
    { id: uuidv4(), name: "Basket", isChecked: false },
    { id: uuidv4(), name: "Flowers", isChecked: false },
    { id: uuidv4(), name: "Decorations", isChecked: false },
  ],

  listItemsAccessories: [
    { id: uuidv4(), name: "All" },
    { id: uuidv4(), name: "Popular" },
    { id: uuidv4(), name: "Weddings" },
    { id: uuidv4(), name: "Baptizing" },
    { id: uuidv4(), name: "Valentine" },
    { id: uuidv4(), name: "Christmas" },
  ],
  listItemsDecorations: [
    { id: uuidv4(), name: "All" },
    { id: uuidv4(), name: "Popular" },
    { id: uuidv4(), name: "Photo Zones" },
    { id: uuidv4(), name: "Rental" },
  ],
};
